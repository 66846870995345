import BaseResource from './base-resource';

class InternalMessage extends BaseResource {
  static baseUrl = '/internal_messages.json';

  attributes() {
    return {
      domain: this.domain,
      parts: this.submittableParts()
    };
  }

  submittableParts() {
    return _.filter(this.parts, function (part) {
      return part.body && part.body.length;
    });
  }
}

export default InternalMessage;
