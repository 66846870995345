<template>
  <div>
    <div class="titlebar lawyer-titlebar">
      <div class="bottom-20">
        <lawyer-header
          :lawyer="lawyer"
          :current-client="currentClient"
          :resource-lists="resourceLists"
          :on-manage-list="onManageList">
        </lawyer-header>
      </div>

      <div class="bottom-40">
        <hr class="no-margin">
      </div>

      <div class="titlebar-switcher">
        <a href="" :class="{ 'selected': isTabSelected('experience') }" @click.prevent="selectTab('experience')">Experience</a>

        <a href="" :class="{ 'selected': isTabSelected('reviews') }" @click.prevent="selectTab('reviews')">Reviews</a>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div v-if="isTabSelected('experience')">
            <ghost-lawyer-experience :lawyer="lawyer" v-if="isGhost"></ghost-lawyer-experience>

            <lawyer-experience :lawyer="lawyer" v-else></lawyer-experience>
          </div>

          <div v-if="isTabSelected('reviews')">
            <lawyer-reviews
              :lawyer="lawyer"
              :timekeepings="timekeepings"
              :general-reviews="generalReviews"
              :practice-area-options="practiceAreaOptions"
              :on-apply-filters="onApplyFilters"
              :filters-data="filtersData"
              :matter-manager-options="matterManagerOptions">
            </lawyer-reviews>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerHeader from './lawyer-header.vue';
import LawyerExperience from './lawyer-experience.vue';
import GhostLawyerExperience from './ghost-lawyer-experience.vue';
import LawyerReviews from './lawyer-reviews.vue';

export default {
  name: 'ScoutClientLawyerShow',

  components: {
    LawyerHeader,
    LawyerExperience,
    GhostLawyerExperience,
    LawyerReviews
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    timekeepings: {
      type: Array,
      required: true
    },

    generalReviews: {
      type: Array,
      required: true
    },

    practiceAreaOptions: {
      type: Array,
      required: true
    },

    initialTab: {
      type: String,
      required: false
    },

    // TODO: this should be in a store so we don't have to pass it
    currentClient: {
      type: Object,
      required: true
    },

    onApplyFilters: {
      type: Function,
      required: true
    },

    filtersData: {
      type: Object,
      required: true
    },

    matterManagerOptions: {
      type: Array,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    onManageList: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      tab: { experience: 'experience', reviews: 'reviews' }[this.initialTab] || 'experience'
    };
  },

  computed: {
    isGhost() {
      return this.lawyer.isBillingLawyer();
    },

    workspaceName() {
      return this.currentClient.workspace?.name;
    }
  },

  methods: {
    isTabSelected(tab) {
      return this.tab === tab;
    },

    selectTab(tab) {
      if (!this.isTabSelected(tab)) {
        this.lawyer.profileView({ tab });
        this.tab = tab;
      }
    }
  }
};
</script>
