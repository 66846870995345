<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-4 text-uppercase">
          Reviews
        </div>

        <div class="col-sm-8 text-right-not-xs">
          <div class="top-20-xs">
            <a href="" :class="['subtab-link', { 'selected': selectedTabIsMatter }]" @click.prevent="selectTab('matters')">Matter Reviews ({{ timekeepings.length }})</a>

            <a href="" :class="['subtab-link', { 'selected': selectedTabIsGeneral }]" @click.prevent="selectTab('general')">General Reviews ({{ generalReviews.length }})</a>
          </div>
        </div>
      </div>
    </div>

    <div v-show="selectedTabIsMatter">
      <lawyer-matter-review-list-filters
        :on-apply-filters="onApplyFilters"
        :practice-area-options="practiceAreaOptions"
        :matter-manager-options="matterManagerOptions">
      </lawyer-matter-review-list-filters>

      <div class="resource-list clearfix">
        <loading-section name="matterReviews">
          <lawyer-matter-reviews
            v-for="timekeeping in timekeepingsSortedByMatterName"
            :key="`timekeeping-${timekeeping.id}`"
            :timekeeping="timekeeping"
            :review-template="reviewTemplate">
          </lawyer-matter-reviews>

          <div v-if="!timekeepingsSortedByMatterName.length" class="gray-text text-center size-text-16px top-30">
            No matters
          </div>
        </loading-section>
      </div>

      <div class="box-content symmetrical">
        <div class="row tight-columns review-list-item">
          <div class="col-sm-6 size-text-14px top-4">
            Total spent with this lawyer
          </div>

          <div class="col-sm-6 text-right-not-xs size-text-18px bold-weight">
            {{ formattedDollarsSpent }}
          </div>
        </div>
      </div>
    </div>

    <div v-show="selectedTabIsGeneral">
      <lawyer-general-review-list-filters
        :filters-data="filtersData"
        :on-apply-filters="onApplyFilters">
      </lawyer-general-review-list-filters>

      <div class="resource-list clearfix">
        <loading-section name="generalReviews">
          <lawyer-general-review
            v-for="review in generalReviews"
            :key="`review-${review.id}`"
            :review="review"
            :review-template="reviewTemplate">
          </lawyer-general-review>

          <div v-if="!generalReviews.length" class="gray-text text-center size-text-16px top-30 bottom-30">
            No reviews
          </div>
        </loading-section>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerMatterReviews from './lawyer-matter-reviews.vue';
import LawyerGeneralReview from './lawyer-general-review.vue';
import lawyerGeneralReviewListFilters from './lawyer-general-review-list-filters.vue';
import lawyerMatterReviewListFilters from './lawyer-matter-review-list-filters.vue';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';
import { sortBy } from 'lodash';

export default {
  name: 'LawyerMatters',

  components: {
    LawyerGeneralReview,
    LawyerMatterReviews,
    lawyerGeneralReviewListFilters,
    lawyerMatterReviewListFilters
  },

  props: {
    lawyer: {
      type:     Object,
      required: true
    },

    timekeepings: {
      type:     Array,
      required: true
    },

    generalReviews: {
      type:     Array,
      required: true
    },

    practiceAreaOptions: {
      type:     Array,
      required: true
    },

    reviewTemplate: {
      type: Object,
      required: true
    },

    filtersData: {
      type: Object,
      required: true
    },

    onApplyFilters: {
      type: Function,
      required: false
    },

    matterManagerOptions: {
      type:     Array,
      required: true
    }
  },

  data() {
    return {
      selectedTab: 'matters'
    };
  },

  computed: {
    formattedDollarsSpent() {
      return CurrencyFilter.filter(this.lawyer.totalSpend / 100);
    },

    selectedTabIsMatter() {
      return this.selectedTabIs('matters');
    },

    selectedTabIsGeneral() {
      return this.selectedTabIs('general');
    },

    timekeepingsSortedByMatterName() {
      return sortBy(this.timekeepings, ['scoutMatter.name']);
    }
  },

  methods: {
    selectTab(tab) {
      if (tab === this.selectedTab) { return; }

      this.selectedTab = tab;
    },

    selectedTabIs(tab) {
      return this.selectedTab === tab;
    }
  }
};
</script>

<style lang="scss" scoped>
  :deep {
    .loading-modal {
      padding: 30px
    }
  }
</style>
