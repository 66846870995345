import BaseResource from '../base-resource';
import axios from 'axios';

class ScoutLawyer extends BaseResource {
  static baseUrl = '/scout/lawyers/:id.json';

  hasBillingData() {
    return false;
  }

  static lawyerPermissionSettings(params) {
    return axios.get('/scout/lawyer_permission_settings.json', { params });
  }

  static admins() {
    return axios.get('/scout/lawyers/admins.json');
  }

  static rfqAvailableLawyers(params) {
    return axios.get('/scout/lawyers/rfq_available_lawyers.json', { params });
  }
}

export default ScoutLawyer;
