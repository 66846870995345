<template>
  <edit-modal
    :input="scoutTag"
    :title="modalTitle"
    :on-cancel="reset"
    :on-save="submitForm">
    <template #modal-trigger="modalProps">
      <div>
        <a href="" class="semibold-weight" @click.prevent="modalProps.openModal()">{{ triggerName }}</a>
      </div>
    </template>

    <template #default="{ inputCopy: scoutTagCopy }">
      <div class="row tight-columns bottom-30">
        <div class="col-sm-4">
          <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
            <label for="color">Color <span class="inline-help required">*</span></label>

            <dropdown-select-color
              ref="dropdownSelectColor"
              :initial-label="scoutTagCopy.color"
              id-label="color"
              v-model="scoutTagCopy.color"
              :colors="colors"
              :invalid="errors.length > 0">
            </dropdown-select-color>

            <div class="error-text top-5" v-if="errors.length">
              <error-message :errors="errors"></error-message>
            </div>
          </validation-provider>
        </div>

        <div class="col-sm-8 top-30-xs">
          <text-input-vertical
            ref="name"
            label="Label"
            input-name="name"
            id="name"
            rules="required"
            :maxlength="nameMaxlength"
            v-model="scoutTagCopy.name">
          </text-input-vertical>

          <div class="size-text-12px dark-gray-text top-5" v-if="showCharactersLimitedMessage(scoutTagCopy)">
            Limited to {{ nameMaxlength }} characters
          </div>
        </div>
      </div>

      <div v-if="!isPersisted" class="bottom-30">
        <label class="check-option vertical medium-large">
          <span class="normal-weight">Create a Saved Search for this Tag</span>
          <input type="checkbox" name="savedSearch" id="saved-search" v-model="createSavedSearch">
          <span class="check c-box"></span>
        </label>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import DropdownSelectColor from 'vue-app/shared/components/dropdown-select-color.vue';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'ScoutTagEditingModal',

  components: {
    ErrorMessage,
    DropdownSelectColor,
    EditModal,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    scoutTag: {
      type: Object,
      required: true
    },

    onCreate: {
      type: Function,
      default: () => {}
    },

    onUpdate: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      colors: ['blue', 'gray', 'green', 'purple', 'red', 'yellow'],
      nameMaxlength: 30,
      createSavedSearch: false
    };
  },

  computed: {
    isPersisted() {
      return !!this.scoutTag.id;
    },

    triggerName() {
      return this.isPersisted ? 'Edit Tag' : '+ Add a Law Firm Tag';
    },

    modalTitle() {
      return this.isPersisted ? 'Edit Law Firm Tag' : 'Add Law Firm Tag';
    }
  },

  methods: {
    showCharactersLimitedMessage(scoutTagCopy) {
      return scoutTagCopy.name?.length >= this.nameMaxlength;
    },

    submitForm(scoutTagCopy) {
      if (this.isPersisted) {
        return this.onUpdate(scoutTagCopy);
      }
      else {
        return this.onCreate(scoutTagCopy, this.createSavedSearch);
      }
    },

    reset() {
      this.$refs.dropdownSelectColor.reset();
      this.createSavedSearch = false;
    }
  }
};
</script>
