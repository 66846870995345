<template>
  <form role="form" class="a-form filter-popover" novalidate>
    <div class="text-right bottom-15">
      <button type="button" class="pseudo-link-light-gray" @click="clearFilter">
        Clear Filter
      </button>
    </div>

    <div class="keyword-search-container">
      <typeahead-vertical
        :ref="`${field}typeahead`"
        :name="`${field}typeahead`"
        :id="`${field}-typeahead`"
        placeholder="Search..."
        :options="filteredOptions"
        :limit="6"
        option-label-key="label"
        option-value-key="slug"
        :async-function="optionsQueryFunction"
        :excluded-options="selectedTerms"
        @substring-matches-present="setSubstringMatchesPresent"
        @input="addTerm">
      </typeahead-vertical>
    </div>

    <div class="error-text top-10" v-if="noMatches">
      * No matching results
    </div>

    <div v-if="!lockContext && hasSelectedTerms && isOrLogic" class="vertical-center justify-between semibold-weight tight-lines top-15">
      <div>
        Required
      </div>

      <div class="d-flex col-gap-5">
        <button type="button" :class="['toggle-button md', { 'toggle-on': isFilterContext }]" @click="switchFilterContext">
          {{ isFilterContext ? 'Yes' : '&nbsp;No' }}
        </button>
      </div>
    </div>

    <div v-if="!lockContext && hasSelectedTerms && !isOrLogic" class="vertical-center justify-between semibold-weight tight-lines top-15">
      <div>
        Filter Criteria:
      </div>

      <div v-if="!lockContext" class="d-flex col-gap-5">
        Required

        <span v-tooltip.right="tooltipText">
          <svg-icon name="info" class="base-icon smaller"></svg-icon>
        </span>
      </div>
    </div>

    <div v-show="hasSelectedTerms">
      <div :class="['check-options', 'top-15', { 'fade-scroll-container': selectedTerms.length > 6, 'scrolled-up': isScrolledUp, 'scrolled-down': isScrolledDown }]">
        <div ref="scrollable" @scroll="checkScroll" class="fade-scrollable">
          <div
            v-for="(term, index) in selectedTerms"
            :key="term"
            class="d-flex justify-between col-gap-10 bottom-10">
            <label
              class="check-option vertical medium-large"
              :for="`${field}-selected-${index}`">
              {{ term }}
              <input
                type="checkbox"
                :name="`selected${index}`"
                :id="`${field}-selected-${index}`"
                :checked="isTermSelected(term)"
                @change="removeTerm(term)">
              <span class="check c-box"></span>
            </label>

            <button
              type="button"
              v-if="!isOrLogic && !lockContext"
              :class="['toggle-button md', { 'toggle-on': termIsFilterContext(term) }]"
              @click="switchTermContext(term)">
              {{ termIsFilterContext(term) ? 'Yes' : '&nbsp;No' }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-show="!hasSelectedTerms" class="missing-text top-15">
      Start typing to see options.
    </div>

    <div v-if="!lockContext" class="filter-footer multiline semibold-weight">
      Set the “Required” toggle to “Yes” to ensure all search results meet {{ isOrLogic ? 'one of the' : 'the' }} selected criteria.
    </div>
  </form>
</template>

<script>
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import checkScroll from 'vue-app/shared/mixins/check-scroll.js';
import searchFilter from 'vue-app/shared/mixins/search-filter.js';

export default {
  name: 'FilterTypeahead',

  components: {
    TypeaheadVertical,
    SvgIcon
  },

  mixins: [
    checkScroll,
    searchFilter
  ],

  props: {
    searchService: {
      type: Object,
      required: true
    },

    filterParams: {
      type: Object,
      required: true
    },

    optionsQueryFunction: {
      type: Function,
      required: false
    },

    options: {
      type: Array,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      noMatches: false,
      tooltipText: 'Set the “Required” toggle to “Yes” to ensure all search results meet the selected criteria. If the “Required” toggle is applied to multiple filter criteria, the search result set will be limited to results that meet all of the selected criteria.'
    };
  },

  computed: {
    filteredOptions() {
      if (!this.options.length) { return []; }

      return this.options.filter(option => !this.selectedTerms.includes(option.label));
    }
  },

  methods: {
    addTerm(term) {
      if (!term) { return; }
      this.searchService.addSearchTerms(this.field, term, this.termContext(term));

      this.onSearch();

      this.clearSearchTerm();
    },

    removeTerm(term) {
      this.searchService.removeSearchTerm(this.field, term, this.termContext(term));

      this.onSearch();
    },

    clearFilter() {
      this.clearSearchTerm();
      this.noMatches = false;
      this.searchService.unsetFilter(this.field);
      this.onSearch();
    },

    clearSearchTerm() {
      this.$refs[`${this.field}typeahead`].reset();
    },

    setSubstringMatchesPresent(matchesPresent) {
      this.noMatches = !matchesPresent;
    }
  }
};
</script>

<style lang="scss" scoped>
  .fade-scroll-container {
    max-height: 169px;

    .fade-scrollable {
      max-height: 169px;
    }
  }
</style>
