<template>
  <validation-observer v-slot="{ handleSubmit, failed }">
    <form role="form" class="a-form" novalidate>
      <div class="size-text-20px">
        Work Performed
      </div>

      <div class="top-2 bottom-20">
        <hr class="no-margin">
      </div>

      <loading-section name="scoutMatters" class="bottom-30" v-if="scoutMatters.length">
        <label>What kind of review do you want to write?</label>

        <div>
          <label class="check-option">
            <span class="normal-weight">Write a general review</span>
            <input type="radio" v-model="reviewType" name="reviewType" value="general" @change="setForGeneralReview">
            <span class="check c-radio"></span>
          </label>
        </div>

        <div>
          <label class="check-option">
            <span class="normal-weight">Write a review on a specific matter</span>
            <input type="radio" v-model="reviewType" name="reviewType" value="matter">
            <span class="check c-radio"></span>
          </label>
        </div>

        <div class="row top-5" v-if="reviewTypeIsMatter">
          <div class="col-sm-10 col-md-8 col-lg-6">
            <typeahead-vertical
              :model="matter"
              :force-select="true"
              :options="mattersByName"
              label="Select matter name"
              id="matter-selection"
              option-label-key="name"
              option-value-key="id"
              rules="required"
              :show-required-label="validateMatterSelection"
              :initial-value="selectedMatterName"
              @input="selectMatter">
            </typeahead-vertical>
          </div>
        </div>
      </loading-section>

      <div class="bottom-30" v-if="firmLocationsData.length">
        <label>In which office location did this work take place?</label>

        <div class="row">
          <div class="col-sm-10 col-md-8 col-lg-6">
            <dropdown-select
              id-label="firm-location"
              placeholder="Select One"
              :options="firmLocationsData"
              v-model="review.lawFirmLocationId"
              value-key="id"
              :initial-label="locationName(review.lawFirmLocation)"
              label-key="name">
            </dropdown-select>
          </div>
        </div>
      </div>

      <div class="bottom-30" v-if="practiceAreaNames.length">
        <label>What practice areas did this work include?</label>

        <div class="row">
          <div class="col-sm-10 col-md-8 col-lg-6">
            <multi-select-dropdown
              :value="review.practiceAreaNames"
              :options="practiceAreaNames"
              @change="updatePracticeNames">
            </multi-select-dropdown>

            <tag-list
              class="top-10"
              :tags="review.practiceAreaNames"
              :on-remove="removePracticeAreaName"
              :value-fn="getPracticeAreaName"
              v-if="review.practiceAreaNames.length">
            </tag-list>
          </div>
        </div>
      </div>

      <div class="bottom-20" v-if="template.complexityShown">
        <question-level-selector
          field="complexity"
          :value="review.complexity"
          :label="template.complexityPromptText"
          :options="complexityLevels"
          @change="updateComplexityScore($event)">
        </question-level-selector>
      </div>

      <div class="bottom-20" v-if="template.riskShown">
        <question-level-selector
          field="risk"
          :value="review.risk"
          :label="template.riskPromptText"
          :options="riskLevels"
          @change="updateRiskScore($event)">
        </question-level-selector>
      </div>

      <div class="bottom-20" v-for="question in booleanQuestions" :key="`boolean-question-${question.title}`">
        <question-yes-no-selector :field="question.title" :label="question.text" :highlightable-answer-type="booleanResponse(question.title)" :on-change="changeBooleanResponse"></question-yes-no-selector>
      </div>

      <div class="bottom-20 select-questions" v-for="(question, index) in selectQuestions" :key="`select-question-${question.title}`">
        <label>{{ question.text }}</label>

        <div class="row">
          <div class="col-sm-10 col-md-8 col-lg-6">
            <dropdown-select
              :id-label="`select-question-${index}`"
              placeholder="Select One"
              :initial-label="selectQuestionResponse(question.title)"
              :options="question.options"
              v-model="findSelectQuestionResponse(question.title).value">
            </dropdown-select>
          </div>
        </div>
      </div>

      <div class="size-text-20px top-30">
        Recommendation Score
      </div>

      <div class="top-2 bottom-20">
        <hr class="no-margin">
      </div>

      <div class="bottom-40">
        <label>{{ recommendationPrompt }}<span class="left-5 red-text">*</span></label>

        <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
          <question-recommendation
            :value="review.recommendation"
            @update-recommendation="updateRecommendation($event)">
          </question-recommendation>

          <div class="error-text top-5" v-if="errors.length">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </div>

      <div class="size-text-20px">
        Law Firm Performance Review
      </div>

      <div class="top-2 bottom-20">
        <hr class="no-margin">
      </div>

      <div class="bottom-30" v-for="question in reviewQuestions" :key="`question-${question.title}`">
        <div class="bottom-30">
          <question-slider
            :value="valueFor(question.title)"
            :heading="question.title"
            :question="question.text"
            :explanation="question.considerations"
            :options="sliderOptions"
            @change="updateSliderValue(question, $event)">
          </question-slider>
        </div>
      </div>

      <div class="bottom-40" v-if="template.overallExperienceShown">
        <div class="size-text-20px">
          Overall Experience
        </div>

        <div class="top-2 bottom-5">
          <hr class="no-margin">
        </div>

        <div class="dark-gray-text text-italic bottom-15">
          Please refrain from disclosing any sensitive matter details in your response.
        </div>

        <label for="experience">{{ template.overallExperienceText }}</label>

        <textarea id="experience" class="form-control" rows="4" v-model="review.experience"></textarea>
      </div>

      <div class="row">
        <div class="col-sm-5 col-md-4">
          <loading-button name="submitReview" lb-class="primary-btn-blue" @lb-click="handleSubmit(submit)">Submit Review</loading-button>
        </div>
      </div>

      <div class="error-text top-10" v-if="failed">
        * One or more required fields are empty. Please fill in the required fields indicated above in order to submit your review.
      </div>
    </form>
  </validation-observer>
</template>

<script>
import ScoutFirmReviewTemplate from 'resources/scout/client/scout-firm-review-template.js';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import QuestionLevelSelector from 'vue-app/scout/client/reviews/question-level-selector.vue';
import QuestionYesNoSelector from 'vue-app/scout/client/reviews/question-yes-no-selector.vue';
import QuestionRecommendation from 'vue-app/scout/client/reviews/question-recommendation.vue';
import QuestionSlider from 'vue-app/scout/client/reviews/question-slider.vue';
import MultiSelectDropdown from 'vue-app/shared/components/multi-select-dropdown.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown';
import TagList from 'vue-app/scout/shared/tag-list.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { sortBy, invert } from 'lodash';

export default {
  name: 'LawFirmReviewForm',

  components: {
    TypeaheadVertical,
    QuestionLevelSelector,
    QuestionYesNoSelector,
    QuestionRecommendation,
    QuestionSlider,
    MultiSelectDropdown,
    DropdownSelect,
    TagList,
    LoadingSection,
    LoadingButton,
    ValidationObserver,
    ValidationProvider
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  mixins: [
    interactionModes
  ],

  props: {
    // firm is Scout::LawFirmBridge object
    firm: {
      type:     Object,
      required: true
    },

    review: {
      type:     Object,
      required: true
    },

    practiceAreaNames: {
      type:     Array,
      required: true
    },

    scoutMatters: {
      type:     Array,
      required: true
    },

    template: {
      type:     Object,
      required: true
    },

    createReview: {
      type:     Function,
      required: true
    }
  },

  data() {
    return {
      dropdownOpen: false,
      elementsToNotCloseOn: [],
      reviewType: (this.scoutMatters.length ? 'matter' : 'general'),
      matter: null
    };
  },

  computed: {
    reviewTypeIsMatter() {
      return this.reviewType === 'matter';
    },

    mattersByName() {
      return sortBy(this.scoutMatters, 'name');
    },

    validateMatterSelection() {
      return this.reviewTypeIsMatter && !!this.matter && !this.review.scoutMatterId;
    },

    recommendationPrompt() {
      return this.template.recommendationText || `How likely are you to recommend ${this.firm.name} to a colleague?`;
    },

    reviewQuestions() {
      return this.template.reviewQuestions();
    },

    responseAnswerLegend() {
      return ScoutFirmReviewTemplate.responseAnswerLegend();
    },

    sliderOptions() {
      return {
        'N/A':                  0,
        'Poor':                 1,
        'Development Needed':   2,
        'Meets Expectations':   3,
        'Exceeds Expectations': 4,
        'Outstanding':          5
      };
    },

    sliderOptionsByValue() {
      return invert(this.sliderOptions);
    },

    firmLocationsData() {
      return this.firm.locations.map(location => {
        return {
          id: location.id,
          name: this.locationName(location)
        };
      });
    },

    complexityLevels() {
      return [
        { score: 1, label: this.template.complexityLowText },
        { score: 2, label: this.template.complexityMediumText },
        { score: 3, label: this.template.complexityHighText }
      ];
    },

    riskLevels() {
      return [
        { score: 1, label: this.template.riskLowText },
        { score: 2, label: this.template.riskMediumText },
        { score: 3, label: this.template.riskHighText }
      ];
    },

    selectedMatterName() {
      if (!this.matter) { return null; }
      return this.matter.name;
    },

    booleanQuestions() {
      return this.template.booleanQuestions();
    },

    selectQuestions() {
      return this.template.selectQuestions();
    }
  },

  watch: {
    scoutMatters(matters) {
      // If it has a scout matter, set as such
      // If persisted without a scout matter, don't default to matter selection
      // Otherwise, default to matter selection if there are any matters
      if (this.review.scoutMatterId) {
        this.reviewType = 'matter';
        this.matter = this.mattersByName.find((matter) => matter.id === this.review.scoutMatterId);
      }
      else if (this.review.id) {
        this.reviewType = 'general';
      }
      else {
        this.reviewType = (matters.length ? 'matter' : 'general');
      }
    }
  },

  methods: {
    selectMatter(matterId) {
      if (matterId) {
        this.review.scoutMatterId = matterId;
      }
    },

    setForGeneralReview() {
      this.matter = null;
      this.review.scoutMatterId = null;
    },

    updateRecommendation(score) {
      this.review.recommendation = score;
    },

    updateSliderValue(question, valObj) {
      const reviewResponse = this.review.responses.find(resp => resp.field === question.title);
      reviewResponse.value = valObj['value'];
    },

    updateComplexityScore(score) {
      this.review.complexity = score;
    },

    updateRiskScore(score) {
      this.review.risk = score;
    },

    locationName(location) {
      if (!location) { return null; }

      if (location.country === 'United States') {
        return `${location.city}, ${location.state}`;
      }
      else {
        return `${location.city}, ${location.country}`;
      }
    },

    updatePracticeNames(practiceAreaNames) {
      this.review.practiceAreaNames = practiceAreaNames;
    },

    removePracticeAreaName(index) {
      this.review.practiceAreaNames.splice(index, 1);
    },

    getPracticeAreaName(practiceAreaName) {
      return practiceAreaName;
    },

    buildResponses() {
      let responses = [];

      for (let fieldKey in this.review.responses) {
        responses.push({ field: fieldKey, value: this.review.responses[fieldKey] });
      }

      return responses;
    },

    submit() {
      this.createReview();
    },

    valueFor(questionTitle) {
      const numericValue = this.review.responses.find(resp => resp.field === questionTitle).value;
      return this.sliderOptionsByValue[numericValue];
    },

    findBooleanResponse(field) {
      return this.review.booleanResponses.find(resp => resp.field === field);
    },

    booleanResponse(field) {
      return this.findBooleanResponse(field)?.value;
    },

    changeBooleanResponse(field, booleanValue) {
      let response = this.findBooleanResponse(field);

      response['value'] = booleanValue;
    },

    findSelectQuestionResponse(field) {
      return this.review.selectQuestionResponses.find(resp => resp.field === field);
    },

    selectQuestionResponse(field) {
      return this.findSelectQuestionResponse(field)?.value;
    }
  }
};
</script>

<style lang="scss" scoped>
  .button {
    width: 150px;
  }
</style>
