<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="`${titleColClass} text-uppercase`">
          {{ title }}
        </div>

        <div v-if="linksSlotPassed" class="col-sm-7 size-text-12px normal-weight text-right-not-xs">
          <slot name="links"></slot>
        </div>
      </div>
    </div>

    <div :class="['box-content', contentClass]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    contentClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    linksSlotPassed() {
      return this.$slots.links;
    },

    titleColClass() {
      return this.linksSlotPassed ? 'col-sm-5' : 'col-xs-12';
    }
  }
};
</script>
