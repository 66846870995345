<template>
  <validation-observer v-slot="{ handleSubmit, failed: formInvalid }">
    <form role="form" class="a-form" novalidate>
      <div class="row vertical-bottom-not-xs" v-if="!isEditing">
        <div class="col-sm-9 size-text-17px bold-weight">
          Submit Your Quote
        </div>

        <div class="col-sm-3 bottom-10 top-20-xs">
          <loading-button name="saveQuote" lb-class="primary-btn-white" :dark="true" @lb-click="save()">Save & Exit</loading-button>
        </div>
      </div>

      <div class="top-5 bottom-10" v-if="!isEditing">
        <hr class="no-margin">
      </div>

      <div class="row tight-columns bottom-30" v-if="!isEditing || formInvalid">
        <div class="col-sm-7 col-sm-push-5 text-right-not-xs">
          <div class="error-text" v-if="formInvalid">
            * One or more fields are invalid. Please fix errors as indicated below.
          </div>
        </div>

        <div class="col-sm-5 col-sm-pull-7 top-10-xs" v-if="!isEditing">
          Fields marked with <span class="inline-help required">*</span> are required.
        </div>
      </div>

      <div class="bottom-40" v-for="questionGroup in orderedQuestionGroups" :key="questionGroup.id">
        <quote-form-question-group
          v-if="formDataIsBuilt"
          :question-group="questionGroup"
          :form-data="formData"
          :rates-are-required="ratesAreRequired"
          :quote-last-submitted-at="quoteLastSubmittedAt"
          :has-new-question-activities="hasNewQuestionActivities"
          :is-connecting="isConnecting"
          :on-load-firm-lawyers="onLoadFirmLawyers"
          :on-answer-change="updateAnswer"
          :on-suggested-lawyer-change="updateSuggestedLawyer"
          :on-add-firm-suggested-lawyers="addFirmSuggestedLawyers"
          :on-remove-firm-suggested-lawyer="removeFirmSuggestedLawyer"
          :quote="quote">
        </quote-form-question-group>
      </div>

      <div class="row">
        <div class="col-sm-4 col-lg-3">
          <loading-button name="saveQuote" lb-class="primary-btn-night-blue" @lb-click="handleSubmit(submitQuote)">Submit Quote</loading-button>
        </div>

        <div class="col-sm-3 top-20-xs">
          <loading-button name="saveQuote" lb-class="primary-btn-white" :dark="true" @lb-click="save()" v-if="!isEditing">Save & Exit</loading-button>
          <button type="button" class="primary-btn-white" @click="cancel" v-else>Cancel</button>
        </div>
      </div>

      <div class="error-text top-10" v-if="formInvalid">
        * One or more fields are invalid. Please fix errors as indicated above.
      </div>
    </form>
  </validation-observer>
</template>

<script>
import QuoteFormQuestionGroup from './quote-form-question-group.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import { ValidationObserver } from 'vee-validate';
import RfqSuggestedLawyer from 'resources/rfq-suggested-lawyer.js';
import { clone, find, flatMap, map, orderBy, without, cloneDeep } from 'lodash';

export default {
  name: 'QuoteForm',

  components: {
    QuoteFormQuestionGroup,
    LoadingButton,
    ValidationObserver
  },

  props: {
    quote: {
      type: Object,
      required: true
    },

    isConnecting: {
      type: Boolean,
      required: true
    },

    onLoadFirmLawyers: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onUpdateFileAnswer: {
      type: Function,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    },

    isEditing: {
      type: Boolean,
      default: false
    },

    onCancel: {
      type: Function,
      required: false
    },

    hasNewQuestionActivities: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      formData: {
        answers: [],
        clientSuggestedLawyers: [],
        firmSuggestedLawyers: []
      },
      firmSuggestedLawyersToDestroy: [],
      formDataIsBuilt: false,
      formDataOriginal: {
        answers: [],
        clientSuggestedLawyers: [],
        firmSuggestedLawyers: []
      },
      newAttachments: []
    };
  },

  computed: {
    orderedQuestionGroups() {
      return orderBy(this.quote.rfq.questionGroups, 'position');
    },

    questions() {
      return flatMap(this.quote.rfq.questionGroups, 'questions');
    },

    feeStructureQuestion() {
      return find(this.questions, { shortname: 'fee_structure' });
    },

    feeStructureAnswer() {
      if (!this.feeStructureQuestion) { return null; }
      return find(this.formData.answers, { scoutRfqQuestionId: this.feeStructureQuestion.id });
    },

    canAnswerFeeQuestion() {
      return !!this.feeStructureQuestion?.isIncluded && this.feeStructureAnswer;
    },

    ratesAreRequired() {
      return this.canAnswerFeeQuestion && this.feeStructureAnswer.value === 'hourly';
    },

    formParams() {
      const firmSuggestedLawyers = this.formData.firmSuggestedLawyers.concat(this.firmSuggestedLawyersToDestroy);

      return {
        id: this.quote.id,
        clientSuggestedLawyersAttributes: this.formData.clientSuggestedLawyers,
        firmSuggestedLawyersAttributes: firmSuggestedLawyers,
        answersAttributes: this.formData.answers,
        newAttachments: this.newAttachments,
        originalAttachments: this.getOriginalAttachments()
      };
    },

    quoteLastSubmittedAt() {
      return this.quote.lastUpdatedAt || this.quote.dateResponded;
    }
  },

  mounted() {
    this.initFormData();
  },

  methods: {
    getAttachments(attachments) {
      const attachmentIds = [];

      if (attachments !== undefined) {
        attachments.forEach(attachment => {
          attachmentIds.push({
            id: attachment.id,
            name: attachment.name
          });
        });
      }

      return attachmentIds;
    },

    getOriginalAttachments() {
      let originalAttachments = [];

      this.formDataOriginal.answers.forEach(answer => {
        if (answer.answerType === 'file_upload') {
          originalAttachments = this.getAttachments(answer.attachments);
        }
      });

      return originalAttachments;
    },

    initFormData() {
      this.formData.answers = this.initAnswers();
      this.formDataOriginal = cloneDeep(this.formData);
      if (this.canAnswerFeeQuestion) {
        this.feeStructureAnswer.value = this.feeStructureAnswer.value || 'hourly';
      }
      this.formData.clientSuggestedLawyers = clone(this.quote.clientSuggestedLawyers);
      this.formData.firmSuggestedLawyers = clone(this.quote.firmSuggestedLawyers);
      this.formDataIsBuilt = true;
    },

    initAnswers() {
      return flatMap(this.quote.rfq.questionGroups, (group) => {
        return map(group.questions, (question) => {
          return find(this.quote.answers, { scoutRfqQuestionId: question.id }) ||
            this.buildAnswerFor(question);
        });
      });
    },

    buildAnswerFor(question) {
      return {
        id: null,
        scoutRfqQuestionId: question.id,
        value: (question.questionType === 'range' ? [] : null),
        answerType: question.questionType
      };
    },

    buildAttachmentsFor(value) {
      // NOTE: value is either an array of attachments (with ids) to be destroyed
      // or an array of file objects to be added as attachments
      if (!value.length || value[0].id) { return value; }
      return map(value, (file) => { return { file: file }; });
    },

    updateAnswer(questionId, value) {
      const answer = find(this.formData.answers, { scoutRfqQuestionId: questionId });

      if (answer.answerType === 'file_upload') {
        return this.onUpdateFileAnswer(answer, this.buildAttachmentsFor(value))
          .then((response) => {
            answer.id = response.id;
            this.newAttachments = this.getAttachments(response.attachments);
            return response.attachments;
          });
      }
      else {
        answer.value = value;
        return Promise.resolve(value);
      }
    },

    updateSuggestedLawyer(suggestedLawyer, field, value) {
      suggestedLawyer[field] = value;
      return Promise.resolve(value);
    },

    addFirmSuggestedLawyers(lawyersData) {
      let suggestedLawyer = null;

      lawyersData.forEach(lawyerData => {
        suggestedLawyer = new RfqSuggestedLawyer(lawyerData);
        this.formData.firmSuggestedLawyers.push(suggestedLawyer.toFormData());
      });
    },

    removeFirmSuggestedLawyer(suggestedLawyer) {
      if (suggestedLawyer.id) {
        suggestedLawyer['_destroy'] = true;
        this.firmSuggestedLawyersToDestroy.push(suggestedLawyer);
      }

      this.formData.firmSuggestedLawyers = without(this.formData.firmSuggestedLawyers, suggestedLawyer);
    },

    save() {
      this.onSave(this.formParams);
    },

    cancel() {
      this.formData = cloneDeep(this.formDataOriginal);
      this.newAttachments = [];
      this.onCancel();
    },

    submitQuote() {
      this.onSubmit(this.formParams);
    }
  }
};
</script>
