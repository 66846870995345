<template>
  <div>
    <div class="row tight-columns">
      <div class="col-sm-8 dash-project-container" @click="viewProject">
        <div class="dash-project-name text-ellipsis">
          {{ matter.client.businessName || matter.client.fullName }}
        </div>

        <div class="text-ellipsis">
          <span class="semibold-weight purple-text right-5">{{ matter.nameForLawyersReference }}</span>
          <span class="right-5">&#8231;</span>
          <span>Contact: <span class="semibold-weight">{{ matter.client.fullName }}</span></span>

          <template v-if="matter.wework">
            <span class="left-5 right-5">&#8231;</span>
            <span class="purple-text semibold-weight">via WeWork</span>
          </template>
        </div>
      </div>

      <div class="col-sm-4 top-4">
        <div class="row tight-columns text-right-not-xs">
          <div class="col-xs-9 col-lg-8">
            <a :href="projectPath" class="nv-button-white smaller">View Project</a>
          </div>

          <div class="col-xs-3 col-lg-4">
            <dropdown-menu>
              <li class="menu-item" role="menuitem">
                <a href="" @click.prevent="messageClient"><svg-icon name="envelope" class="base-icon"></svg-icon>Message Client</a>
              </li>

              <li class="menu-item" role="menuitem">
                <a href="" @click.prevent="openConfirm"><svg-icon name="archive" class="base-icon"></svg-icon>Close Project</a>
              </li>
            </dropdown-menu>
          </div>
        </div>
      </div>
    </div>

    <request-confirmation
      :show="showConfirm"
      message="Are you sure you've completed all work and billing for this project and are ready to close it?"
      @on-modal-yes="toggleCloseProject"
      @on-modal-close="closeConfirm">
    </request-confirmation>
  </div>
</template>

<script>
import RequestConfirmation from 'vue-app/shared/components/request-confirmation';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';

export default {
  name: 'RecentProject',

  components: {
    RequestConfirmation,
    DropdownMenu
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    sendMessageService: {
      type: Object,
      required: true
    },

    closeProject: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      showConfirm: false
    };
  },

  computed: {
    projectPath() {
      return `/lawyer-app/projects/${this.matter.id}`;
    }
  },

  methods: {
    openConfirm() {
      this.showConfirm = true;
    },

    closeConfirm() {
      this.showConfirm = false;
    },

    toggleCloseProject() {
      this.closeProject(this.matter);
    },

    messageClient() {
      this.sendMessageService.modal([this.matter.client]);
    },

    viewProject() {
      window.location.href = this.projectPath;
    }
  }
};
</script>
