import axios from 'axios';
import BaseResource from '../base-resource';

class ScoutConnection extends BaseResource {
  static baseUrl = '/scout/connections/:id.json';

  static toggleActive(id) {
    return axios.put(`/scout/connections/${id}/toggle_active`);
  }
}

export default ScoutConnection;
