<template>
  <div name="form" class="a-form filter-popover">
    <div class="row tight-columns vertical-center bottom-20">
      <div class="col-xs-4 col-sm-3 semibold-weight">
        Required
      </div>

      <div class="col-xs-3">
        <button
          type="button"
          :class="['toggle-button md', { 'toggle-on': isFilterContext }]"
          @click="switchFilterContext">
          {{ isFilterContext ? 'Yes' : '&nbsp;No' }}
        </button>
      </div>

      <div class="col-xs-5 col-sm-6 text-right">
        <button
          type="button"
          class="pseudo-link-light-gray"
          @click="clearFilter">
          Clear Filter
        </button>
      </div>
    </div>

    <div class="bottom-10">
      <label :for="radioKeywordId" class="check-option vertical medium-large">
        Search by State or Country
        <input type="radio" :name="locationInputName" :id="radioKeywordId" required v-model="searchType" :value="'keyword'" @change="toggleSearchType">
        <span class="check c-radio"></span>
      </label>
    </div>

    <div class="bottom-20">
      <label :for="radioRadiusId" class="check-option vertical medium-large">
        Search by City or County
        <input type="radio" :name="locationInputName" :id="radioRadiusId" required v-model="searchType" :value="'radius'" @change="toggleSearchType">
        <span class="check c-radio"></span>
      </label>
    </div>

    <div v-if="searchType === 'keyword'">
      <div class="keyword-search-container typeahead-container">
        <typeahead-vertical
          :ref="`${field}Keyword`"
          :name="`${field}Keyword`"
          :id="`${field}-keyword`"
          placeholder="Search..."
          :options="filteredKeywordOptions"
          :limit="6"
          :excluded-options="selectedKeywordOptions"
          @substring-matches-present="setSubstringMatchesPresent"
          @input="addKeywordTerm">
        </typeahead-vertical>

        <svg-icon name="search" class="base-icon keyword-search-icon"></svg-icon>
      </div>

      <div class="error-text top-10" v-show="noKeywordMatches">
        * No matching results
      </div>

      <div class="top-15" fade-scroll="top bottom">
        <div class="check-options">
          <div v-for="(keywordTerm, index) in selectedKeywordOptions" :key="keywordTerm" class="d-flex justify-between col-gap-10 list-spacing-10">
            <label class="check-option medium-large no-margin" :for="`selected-${index}`">
              {{ keywordTerm }}

              <input type="checkbox" :name="`selected${index}`" :id="`selected-${index}`" checked @click.stop="removeKeywordTerm(keywordTerm)">
              <span class="check c-box"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="filter-footer multiline semibold-weight">
        Search results display providers located in the selected state or country. Set the “Required” toggle to “Yes” to ensure all search results meet one of the selected criteria.
      </div>
    </div>

    <div v-if="searchType === 'radius'">
      <div class="keyword-search-container typeahead-container">
        <input
          type="text"
          :name="`${field}Terms`"
          :id="`${field}-terms`"
          class="form-control keyword-search-input"
          placeholder="Search..."
          autocomplete="off"
          v-model="radiusTerm"
          @keyup.enter="addRadiusTerm(radiusTerm)">

        <svg-icon name="search" class="base-icon keyword-search-icon" @click="addRadiusTerm(term)"></svg-icon>
      </div>

      <div class="error-text top-10" v-show="noCoordinatesFound">
        * Location not found
      </div>

      <div class="top-15" fade-scroll="top bottom">
        <div class="check-options">
          <div v-for="(term, index) in selectedRadiusOptionNames" :key="term" class="d-flex justify-between col-gap-10 list-spacing-10">
            <label class="check-option medium-large no-margin" :for="`selected-${index}`">
              {{ term }}

              <input type="checkbox" :name="`selected${index}`" :id="`selected-${index}`" checked @click.stop="removeRadiusTerm(term)">
              <span class="check c-box"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="filter-footer multiline semibold-weight">
        Search results display providers near the selected location. Set the “Required” toggle to “Yes” to ensure all search results meet one of the selected criteria.
      </div>
    </div>
  </div>
</template>

<script>
import Address from 'src/resources/address.js';
import searchFilter from 'vue-app/shared/mixins/search-filter.js';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { difference, uniq, uniqueId } from 'lodash';
import Vue from 'vue';

export default {
  name: 'FilterLocation',

  components: {
    TypeaheadVertical,
    SvgIcon
  },

  mixins: [
    searchFilter
  ],

  props: {
    filterParams: {
      type: Object,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    searchService: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      searchType: this.searchService.hasFilter('geo_locations') ? 'radius' : 'keyword',
      noCoordinatesFound: false,
      noKeywordMatches: false,
      radiusTerm: null,
      field: this.searchService.hasFilter('geo_locations') ? 'geo_locations' : 'location'
    };
  },

  computed: {
    filteredKeywordOptions() {
      return difference(this.options, this.selectedKeywordOptions).map(option => option.label);
    },

    selectedKeywordOptions() {
      return this.searchService.currentSelections('location', this.filterContext);
    },

    selectedRadiusOptions() {
      return this.searchService.currentSelections('geo_locations', this.filterContext)?.locations || [];
    },

    selectedRadiusOptionNames() {
      return this.selectedRadiusOptions.map(option => option.name);
    },

    locationInputName() {
      return uniqueId('location-input');
    },

    radioKeywordId() {
      return uniqueId('radio-keyword');
    },

    radioRadiusId() {
      return uniqueId('radio-radius');
    }
  },

  methods: {
    toggleSearchType() {
      const searchParamsSet = this.searchService.hasFilter('location') || this.searchService.hasFilter('geo_locations');
      this.field = this.searchType === 'keyword' ? 'location' : 'geo_locations';

      if (!searchParamsSet) { return; }

      this.$refs[`${this.field}Keyword`]?.reset();
      this.clearFilter();
    },

    addRadiusTerm(term) {
      if (this.selectedRadiusOptionNames.some(option => option === term) || !term?.length) { return; }

      this.getCoordinates(term)
        .then((coordinates) => {
          this.noCoordinatesFound = false;
          this.addRadiusSearchTerms(term, coordinates);
          this.onSearch();

          this.radiusTerm = null;
        })
        .catch(() => {
          this.noCoordinatesFound = true;
        });
    },

    getCoordinates(term) {
      return new Promise((resolve, reject) => {
        Address.geocode({ address: { location: term } })
          .then((response) => {
            const coordinates = response.data.coordinates;

            if (coordinates.length) {
              resolve(coordinates[0] + ',' + coordinates[1]);
            }
            else {
              reject(new Error('No coordinates found'));
            }
          });
      });
    },

    addRadiusSearchTerms(term, coordinates) {
      const context = this.filterContext;

      Vue.set(
        this.searchService.searchLogic[context],
        'geo_locations',
        {
          locations: uniq([
            ...this.selectedRadiusOptions,
            { 'name': term, 'origin': coordinates }
          ])
        }
      );
    },

    addKeywordTerm(keywordTerm) {
      if (!this.canAddKeywordTerm(keywordTerm)) { return; }

      this.searchService.addSearchTerms('location', keywordTerm, this.filterContext);

      this.onSearch();
      this.$refs[`${this.field}Keyword`].reset();
    },

    removeKeywordTerm(keywordTerm) {
      this.searchService.removeSearchTerm('location', keywordTerm, this.filterContext);

      this.onSearch();
    },

    removeRadiusTerm(radiusTerm) {
      const context = this.filterContext;
      Vue.set(
        this.searchService.searchLogic[context],
        'geo_locations',
        {
          locations: this.selectedRadiusOptions.filter(option => option.name !== radiusTerm)
        }
      );

      if (this.searchService.currentSelections('geo_locations', context)?.locations?.length === 0) {
        this.searchService.unsetFilterContext('geo_locations', context);
      }

      this.onSearch();
    },

    clearFilter() {
      this.onFilterCleared();
      this.onSearch();
    },

    onFilterCleared() {
      this.searchService.unsetFilter('location');
      this.searchService.unsetFilter('geo_locations');

      this.noCoordinatesFound = false;
      this.noKeywordMatches   = false;
      this.radiusTerm         = null;

      this.$refs[`${this.field}Keyword`]?.reset();
    },

    canAddKeywordTerm(keywordTerm) {
      return keywordTerm && keywordTerm.length && !this.selectedKeywordOptions.includes(keywordTerm);
    },

    setSubstringMatchesPresent(matchesPresent) {
      this.noKeywordMatches = !matchesPresent;
    }
  }
};
</script>
