import { castArray, cloneDeep, each, has, isNumber, without, isEmpty, isPlainObject, map, uniq, unset } from 'lodash';
import ScoutSearch from 'resources/scout/scout-search.js';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge.js';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';
import CaseConverters from 'resources/utils/case-converters';
import Vue from 'vue';

class SearchService {
  constructor({ type, itemIds, workspaceId, filterSets, ngSearchService, lawFirmBridgeId }) {
    this.itemIds = itemIds || [];
    this.page = 1;
    this.search = new ScoutSearch({ id: -1, results: [], totalCount: this.itemIds.length });
    this.searchLogic = {
      type: (type || 'lawyers'),
      filters: {},
      queries: {},
      logic: {},
      sortCaseInsensitive: {}
    };

    this.workspaceId = workspaceId;
    this.filterSets = filterSets || ['general'];
    this.view = null;
    this.ngSearchService = ngSearchService;
    this.lawFirmBridgeId = lawFirmBridgeId;
  }

  loadSearch(search) {
    this.searchLogic = JSON.parse(search.searchParams);

    this.setPristine();
  }

  applySearch() {
    this.unsetEmptyFields();

    const params = cloneDeep(this.searchLogic);

    this.applyServiceFilters(params);

    this.page = 1;
    this.setDirty();

    return ScoutSearch.get({ id: this.search.id, search_params: JSON.stringify(CaseConverters.camelToSnakeCaseTransformer(params)), current_page: 1, view: this.view }).then(search => {
      search.id = search.id || -1;

      this.search = search;
      this.search.results = this.mapResults(this.search.results);
      return this.search.results;
    });
  }

  changePage() {
    const params = cloneDeep(this.searchLogic);

    this.applyServiceFilters(params);

    // TODO: Once everything is using the new Vue SearchService we should be able to unstringify the search params.
    return ScoutSearch.get({ id: this.search.id, search_params: JSON.stringify(CaseConverters.camelToSnakeCaseTransformer(params)), current_page: this.page, view: this.view }).then(search => {
      this.search.totalCount = search.totalCount;
      this.search.results = search.results;
      this.search.results = this.mapResults(this.search.results);
      return this.search.results;
    });
  }

  mapResults(results) {
    if (this.isLawyerSearch()) {
      return map(results, (l) => { return new ScoutLawyerBridge(l); });
    }
    else if (this.isLawyerReviewSearch()) {
      return map(results, (r) => {
        r.resource = new ScoutLawyerBridge(r.resource);
        return r;
      });
    }
    else if (this.isFirmReviewSearch()) {
      return map(results, (r) => {
        r.resource = new ScoutFirmBridge(r.resource);
        return r;
      });
    }
    else {
      return map(results, (f) => { return new ScoutFirmBridge(f); });
    }
  }

  addSearchTerms(field, searchTerms, context) {
    if (isPlainObject(searchTerms)) {
      Vue.set(this.searchLogic[context], field, searchTerms);
    }
    else {
      Vue.set(this.searchLogic[context], field, uniq([...this.currentSelections(field, context), ...castArray(searchTerms)]));
    }
  }

  removeSearchTerm(field, searchTerm, context) {
    Vue.set(this.searchLogic[context], field, without(this.currentSelections(field, context), searchTerm));

    if (this.currentSelections(field, context).length === 0) {
      this.unsetFilterContext(field, context);
    }
  }

  setFilterParams(field, params, context) {
    Vue.set(this.searchLogic[context], field, params);
  }

  switchFilterContext(field, currentContext) {
    const newContext = currentContext === 'filters' ? 'queries' : 'filters';

    const currentSelections = this.currentSelections(field, currentContext);

    this.addSearchTerms(field, currentSelections, newContext);
    this.unsetFilterContext(field, currentContext);
  }

  switchTermContext(field, searchTerm, currentContext) {
    const newContext = currentContext === 'filters' ? 'queries' : 'filters';

    this.removeSearchTerm(field, searchTerm, currentContext);
    this.addSearchTerms(field, searchTerm, newContext);
  }

  unsetFilterContext(field, context) {
    Vue.delete(this.searchLogic[context], field);
    this.ngSearchService?.unsetSearchParam(field);
  }

  unsetFilter(field) {
    this.unsetFilterContext(field, 'filters');
    this.unsetFilterContext(field, 'queries');
    this.ngSearchService?.unsetSearchParam(field);
  }

  currentSelections(field, context, isObject = false) {
    return this.searchLogic[context][field] || (isObject ? {} : []);
  }

  setSearchParam(field, searchParam) {
    if (searchParam.context === 'filter') {
      this.searchLogic.filters[field] = searchParam.filter;
    }
    else {
      this.searchLogic.queries[field] = searchParam.filter;
    }
  }

  switchSearchParamContext(field, searchParam) {
    this.unsetSearchParam(field);
    this.setSearchParam(field, searchParam);
  }

  unsetSearchParam(field) {
    unset(this.searchLogic.filters, field);
    unset(this.searchLogic.queries, field);
  }

  hasFilter(field) {
    const currentFilterSelections = this.currentSelections(field, 'filters');
    const currentQuerySelections  = this.currentSelections(field, 'queries');

    return !isEmpty(currentFilterSelections) || !isEmpty(currentQuerySelections);
  }

  setFilterLogic(field, logic) {
    if (!logic) { return; }

    this.searchLogic.logic[field] = logic;
  }

  setDirty() {
    this.dirty = true;
  }

  setPristine() {
    this.dirty = false;
  }

  unsetEmptyFields() {
    each(this.searchLogic.filters, (terms, field) => {
      if (!isNumber(terms) && isEmpty(terms)) {
        unset(this.searchLogic.filters, field);
      }
    });

    each(this.searchLogic.queries, (terms, field) => {
      if (!isNumber(terms) && isEmpty(terms)) {
        unset(this.searchLogic.queries, field);
      }
    });
  }

  applyServiceFilters(searchParams) {
    if (this.itemIds.length) {
      searchParams.filters['id'] = this.itemIds;
      searchParams.logic['id'] = 'or';
    }
    if (this.workspaceId) {
      searchParams.filters['workspace_id'] = this.workspaceId;
    }
  }

  isFilterParamSet(field) {
    return has(this.searchLogic.filters, field);
  }

  isQueryParamSet(field) {
    return has(this.searchLogic.queries, field);
  }

  isLawyerSearch() {
    return this.searchLogic.type === 'lawyers';
  }

  isLawyerReviewSearch() {
    return this.searchLogic.type === 'lawyer_reviews';
  }

  isFirmReviewSearch() {
    return this.searchLogic.type === 'firm_reviews';
  }

  isSavedSearch() {
    return !!this.search.id;
  }

  count() {
    return this.search.totalCount;
  }
}

export default SearchService;
