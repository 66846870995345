<template>
  <div>
    <div class="titlebar inital-height-titlebar">
      <div class="row tight-columns bottom-40">
        <div class="col-xs-10">
          <div class="vertical-center-not-xs">
            <span class="medium-page-heading right-15">RFP Detail: {{ rfq.name || `Reference #${rfq.id}` }}</span>
            <div class="visible-xs-block"></div>
            <status-pill class="top-10-xs" :closed="rfq.status === 'closed' ? true : false"></status-pill>
          </div>
        </div>

        <div v-if="canEditRfq" class="col-xs-2 text-right">
          <dropdown-menu :compact="true">
            <li class="menu-item" role="menuitem">
              <change-rfq-status-modal
                :rfq="rfq"
                :change-rfq-status="changeRfqStatus">
              </change-rfq-status-modal>
            </li>
          </dropdown-menu>
        </div>
      </div>

      <div class="titlebar-switcher text-center">
        <a href="" :class="['right-20', { 'selected': selectedTab === 'overview' }]" @click.prevent="onSelect('overview')">RFP Overview</a>
        <a href="" :class="{ 'selected': selectedTab === 'responses' }" @click.prevent="onSelect('responses')">Review Responses</a>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div v-if="selectedTab === 'overview'">
            <rfq-overview
              :rfq="rfq"
              :update-project-details="updateProjectDetails"
              :available-firms="availableFirms"
              :can-edit-rfq="canEditRfq"
              :add-recipients="addRecipients"
              :only-sent-to-marketplace="onlySentToMarketplace"
              :update-questions="updateQuestions"
              :send-to-marketplace="sendToMarketplace"
              :view-quote="viewQuote"
              :on-select-tab="onSelect">
            </rfq-overview>
          </div>

          <div v-if="selectedTab === 'responses'">
            <rfq-responses
              :rfq="rfq"
              :selected-quote="selectedQuote"
              :only-sent-to-marketplace="onlySentToMarketplace"
              :can-edit-rfq="canEditRfq"
              :update-quote="updateQuote">
            </rfq-responses>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChangeRfqStatusModal from 'vue-app/scout/client/rfqs/change-rfq-status-modal.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import StatusPill from 'vue-app/shared/components/status-pill.vue';
import RfqOverview from 'vue-app/scout/client/rfqs/rfq-overview.vue';
import RfqResponses from 'vue-app/scout/client/rfqs/rfq-responses.vue';
import MessagingService from 'services/scout/messaging-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'ClientRfqShow',

  components: {
    ChangeRfqStatusModal,
    DropdownMenu,
    StatusPill,
    RfqOverview,
    RfqResponses
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    changeRfqStatus: {
      type: Function,
      required: true
    },

    updateQuote: {
      type: Function,
      default: () => {}
    },

    addRecipients: {
      type: Function,
      default: () => {}
    },

    selectedQuote: {
      type: Object
    },

    selectedTabDefault: {
      type: String,
      default: 'overview'
    },

    availableFirms: {
      type: Array,
      required: true
    },

    updateProjectDetails: {
      type: Function,
      required: true
    },

    updateQuestions: {
      type: Function,
      required: true
    },

    sendToMarketplace: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedTab: this.selectedTabDefault
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    canEditRfq() {
      return this.rfq.creatorId === this.currentUser.id || this.currentUser.hasRfqWorkspaceEnabled;
    },

    onlySentToMarketplace() {
      if (!this.rfq.sentToMarketplace) { return false; }

      const numQuotes = this.rfq.lawFirmQuotes.length;
      const numQuotesFromMarketplace = this.rfq.lawFirmQuotes.filter(quote => quote.fromMarketplace).length;
      return numQuotesFromMarketplace === numQuotes;
    }
  },

  methods: {
    onSelect(tabName) {
      if (this.selectedTab === tabName) { return; }

      MessagingService.closeMessaging();
      this.selectedTab = tabName;
    },

    viewQuote(quote) {
      this.selectedTab = 'responses';
      this.selectedQuote = quote;

      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>
