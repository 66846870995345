<template>
  <div>
    <div :class="['titlebar', { 'inital-height-titlebar': showSubmissionStatusAndUpdates }]">
      <div>
        <div class="vertical-center-not-xs">
          <span class="medium-page-heading right-15">RFP Detail: {{ rfq.name }} (Reference #{{ rfq.id }})</span>
          <div class="visible-xs-block"></div>
          <status-badge :quote="quote" class="top-10-xs"></status-badge>
        </div>
      </div>

      <div class="top-5 flex-relative-start" v-if="showSubmissionStatusAndUpdates">
        <div v-if="hasSubmittedResponse" class="right-5">
          Quote was last submitted on {{ dateResponded }}
        </div>

        <div v-if="rfqUpdatedAfterQuoteReceived" class="update-text">
          <span class="dot"></span>
          The RFP was last updated on {{ latestActivityDate }}
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="bottom-30">
            <a :href="`/scout-firm/clients/${workspace.id}/rfps`" class="back-link" v-if="clientView"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Back to Client RFPs</a>

            <a href="/scout-firm/rfps" class="back-link" v-else><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Back to All RFPs</a>
          </div>

          <status-banner icon="x-circle" variant="declined" class="bottom-30" v-if="isDeclinedByClient">
            <div class="pill-gray-text text-uppercase bold-weight">
              Quote Declined
            </div>

            <div>
              Unfortunately, your quote has not been selected by the client.
            </div>
          </status-banner>

          <status-banner icon="checkmark" variant="selected" class="bottom-30" v-if="isSelectedByClient">
            <div class="green-text text-uppercase bold-weight">
              Quote Selected
            </div>

            <div>
              Congratulations! Your quote has been selected by the client.
            </div>
          </status-banner>

          <div class="shadowed-box">
            <div class="box-content symmetrical">
              <div class="row">
                <div class="col-sm-8 size-text-17px bold-weight">
                  Project Details
                </div>

                <div class="col-sm-4 top-10-xs">
                  <messaging-button
                    :rfq="rfq"
                    :quote="quote">
                  </messaging-button>
                </div>
              </div>

              <div class="top-5 bottom-30">
                <hr class="no-margin">
              </div>

              <rfq-detail
                :rfq="rfq"
                :quote="quote"
                :suggested-lawyers="quote.clientSuggestedLawyers">
              </rfq-detail>

              <div class="row top-30" v-if="isNew || isDraft">
                <div class="col-sm-4 col-lg-3">
                  <button type="button" class="primary-btn-night-blue" @click="respondToRfq" :disabled="showingResponse">Respond to this RFP</button>
                </div>

                <div class="col-sm-4 col-lg-3 top-20-xs">
                  <decline-rfq :on-decline="onDecline" :quote="quote" :workspace="workspace"></decline-rfq>
                </div>
              </div>
            </div>
          </div>

          <div id="response-section" class="shadowed-box top-40" v-show="showingResponse">
            <div class="box-content symmetrical">
              <quote-form
                v-if="isNew || isDraft"
                :quote="quote"
                :is-connecting="isConnecting"
                :on-load-firm-lawyers="onLoadFirmLawyers"
                :on-save="onSave"
                :on-update-file-answer="onUpdateFileAnswer"
                :on-submit="onSubmit"
                :has-new-question-activities="hasNewQuestionActivities"
                class="quote-form">
              </quote-form>

              <quote-detail-firm-declined
                v-if="isDeclinedByFirm"
                :quote="quote"
                :is-connecting="isConnecting"
                :on-load-firm-lawyers="onLoadFirmLawyers"
                :on-save="onSave"
                :on-update-file-answer="onUpdateFileAnswer"
                :on-submit="onSubmit"
                :has-new-question-activities="hasNewQuestionActivities">
              </quote-detail-firm-declined>

              <quote-detail-submitted
                v-if="isQuoteSubmitted"
                :rfq="rfq"
                :quote="quote"
                :is-connecting="isConnecting"
                :on-load-firm-lawyers="onLoadFirmLawyers"
                :on-save="onSave"
                :on-update-file-answer="onUpdateFileAnswer"
                :on-submit="onSubmit"
                :has-new-question-activities="hasNewQuestionActivities">
              </quote-detail-submitted>

              <quote-detail-client-declined
                v-if="isDeclinedByClient"
                :rfq="rfq"
                :quote="quote"
                :is-connecting="isConnecting"
                :on-load-firm-lawyers="onLoadFirmLawyers"
                :on-save="onSave"
                :on-update-file-answer="onUpdateFileAnswer"
                :on-submit="onSubmit"
                :has-new-question-activities="hasNewQuestionActivities">
              </quote-detail-client-declined>

              <quote-detail-selected
                v-if="isSelectedByClient"
                :rfq="rfq"
                :quote="quote"
                :is-connecting="isConnecting"
                :on-load-firm-lawyers="onLoadFirmLawyers"
                :on-save="onSave"
                :on-update-file-answer="onUpdateFileAnswer"
                :on-submit="onSubmit"
                :has-new-question-activities="hasNewQuestionActivities">
              </quote-detail-selected>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfqDetail from './rfq-detail.vue';
import StatusBadge from './status-badge.vue';
import DeclineRfq from './decline-rfq.vue';
import QuoteForm from './quote-form.vue';
import QuoteDetailFirmDeclined from './quote-detail-firm-declined.vue';
import QuoteDetailSubmitted from './quote-detail-submitted.vue';
import QuoteDetailClientDeclined from './quote-detail-client-declined.vue';
import QuoteDetailSelected from './quote-detail-selected.vue';
import StatusBanner from 'vue-app/scout/shared/rfqs/status-banner.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import MessagingButton from 'vue-app/mailboxer/scout/messaging-button.vue';
import DateFilter from 'vue-app/shared/lib/date-filter';
import dateComparisons from 'vue-app/shared/mixins/date-comparisons.js';

export default {
  name: 'RFQLawFirmQuotesShow',

  components: {
    RfqDetail,
    StatusBadge,
    DeclineRfq,
    QuoteForm,
    QuoteDetailFirmDeclined,
    QuoteDetailSubmitted,
    QuoteDetailClientDeclined,
    QuoteDetailSelected,
    StatusBanner,
    SvgIcon,
    MessagingButton
  },

  mixins: [dateComparisons],

  props: {
    quote: {
      type: Object,
      required: true
    },

    clientView: {
      type: Boolean,
      default: false
    },

    isConnecting: {
      type: Boolean,
      required: true
    },

    onLoadFirmLawyers: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onUpdateFileAnswer: {
      type: Function,
      required: true
    },

    onDecline: {
      type: Function,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      showingResponse: true
    };
  },

  computed: {
    workspace() {
      return this.quote.workspace;
    },

    rfq() {
      return this.quote.rfq;
    },

    isNew() {
      return this.quote.status === 'new_rfq';
    },

    isDraft() {
      return this.quote.status === 'draft';
    },

    isDeclinedByFirm() {
      return this.quote.status === 'declined_by_firm';
    },

    isQuoteSubmitted() {
      return this.quote.status === 'quote_submitted';
    },

    isSelectedByClient() {
      return this.quote.status === 'selected_by_client';
    },

    isDeclinedByClient() {
      return this.quote.status === 'declined_by_client';
    },

    dateResponded() {
      return DateFilter.filter(this.quote.lastUpdatedAt || this.quote.dateResponded);
    },

    latestActivityDate() {
      return DateFilter.filter(this.rfq.latestActivity?.createdAt);
    },

    hasSubmittedResponse() {
      return !!this.quote.dateResponded || !!this.quote.lastUpdatedAt;
    },

    latestActivityAfterReceivedDate() {
      return this.dateIsAfter(this.rfq.latestActivity?.createdAt, this.quote.dateReceived);
    },

    hasUpdates() {
      return !!this.quote.rfq.latestActivity;
    },

    rfqUpdatedAfterQuoteReceived() {
      return this.hasUpdates && this.latestActivityAfterReceivedDate;
    },

    showSubmissionStatusAndUpdates() {
      return this.hasSubmittedResponse || this.rfqUpdatedAfterQuoteReceived;
    },

    hasNewQuestionActivities() {
      if (this.quote.latestQuestionActivity && !this.quoteLastSubmittedAt) { return true; }

      return this.dateIsAfter(this.quote.latestQuestionActivity?.createdAt, this.quoteLastSubmittedAt);
    },

    quoteLastSubmittedAt() {
      return this.quote.lastUpdatedAt || this.quote.dateResponded;
    }
  },

  mounted() {
    this.showingResponse = !this.isNew;
  },

  methods: {
    respondToRfq() {
      this.showingResponse = true;
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/scout/variables";

  .quote-form {
    @media (min-width: $screen-sm-min) {
      margin-top: -10px;
    }

    @media (min-width: $screen-md-min) {
      margin-top: -20px;
    }
  }

  .update-text {
    position: relative;
    padding-left: 11px;
  }

  .dot {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    background-color: $p-yellow;
    border-radius: 50%;
    left: 0;
  }

  .messaging-button {
    top: -25px;
    right: -15px;

    @media (min-width: $screen-sm-min) {
      top: 0;
    }
  }
</style>
