<template>
  <div class="row tight-columns vertical-center-not-xs">
    <div class="col-sm-2">
      {{ upload.id }}
    </div>

    <div class="col-sm-2">
      {{ receivedDate }}
    </div>

    <div class="col-sm-2">
      {{ receivedTime }}
    </div>

    <div class="col-sm-2">
      <div class="upload-status-box">
        <span>{{ status.text }}</span>
        <span :class="`status-icon ${status.iconClass}`"></span>
      </div>
    </div>

    <div class="col-sm-2">
      {{ upload.lastRunErrors.length }}
    </div>

    <div class="col-sm-2 text-right-not-xs">
      <a v-if="upload.status > 1" :href="uploadUrl">View Details</a>
      <a v-else href="" @click.prevent="reload" class="blue-text"><svg-icon name="refresh" class="base-icon right-10"></svg-icon>Refresh</a>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon';
import DateFilter from 'vue-app/shared/lib/date-filter';

export default {
  name: 'UploadListItem',

  components: { SvgIcon },

  props: {
    upload: {
      type:     Object,
      required: true
    },

    urlConstructor: {
      type: Function,
      required: true
    }
  },

  data: function () {
    return {
      statuses: {
        0: { text: 'In Progress', iconClass: 'in-progress' },
        1: { text: 'In Progress', iconClass: 'in-progress' },
        2: { text: 'In Progress', iconClass: 'in-progress' },
        3: { text: 'Completed', iconClass: 'completed' },
        4: { text: 'Failed', iconClass: 'failed' }
      }
    };
  },

  computed: {
    receivedDate: function () {
      return DateFilter.filter(this.upload.receivedAt);
    },

    receivedTime: function () {
      return DateFilter.filter(this.upload.receivedAt, {
        timeStyle: 'short'
      });
    },

    status: function () {
      return this.statuses[this.upload.status];
    },

    uploadUrl: function () {
      return this.urlConstructor(this.upload.id);
    }
  },

  methods: {
    reload: function () {
      window.location.reload();
    }
  }
};
</script>
