<template>
  <div>
    <div class="breadcrumbs-container with-buttons">
      <div class="container">
        <div class="row vertical-center-not-xs">
          <div class="col-xs-12 col-sm-8 col-md-9">
            <a :href="`/scout-company/law-firms/${firm.id}/reviews`" class="back-link" target="_self"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Cancel & Exit</a>
          </div>

          <div class="col-xs-10 col-sm-4 col-md-3 top-20-xs">
            <button type="button" class="secondary-btn-blue transparent" @click="saveDraft">Save & Complete Later</button>
          </div>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-4">
              <law-firm-card :firm="firm"></law-firm-card>
            </div>

            <div class="col-sm-8 top-30-xs">
              <div class="shadowed-box">
                <div class="box-content">
                  <div class="size-text-24px bottom-10">
                    Submit a Law Firm Review
                  </div>

                  <div class="bottom-30">
                    {{ instructions }}
                  </div>

                  <law-firm-review-form
                    ref="lawFirmReviewForm"
                    :firm="firm"
                    :review="review"
                    :practice-area-names="practiceAreaNames"
                    :scout-matters="scoutMatters"
                    :template="template"
                    :create-review="createReview">
                  </law-firm-review-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LawFirmCard from 'vue-app/scout/client/reviews/firm-card.vue';
import LawFirmReviewForm from 'vue-app/scout/client/reviews/law-firm-review-form.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'LawFirmsReviewNew',

  components: {
    LawFirmCard,
    LawFirmReviewForm
  },

  props: {
    firm: {
      type:     Object,
      required: true
    },

    review: {
      type:     Object,
      required: true
    },

    practiceAreaNames: {
      type:      Array,
      required:  true
    },

    scoutMatters: {
      type:     Array,
      required: true
    },

    template: {
      type:     Object,
      required: true
    },

    saveDraft: {
      type:     Function,
      required: true
    },

    createReview: {
      type:     Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentProduct']),

    instructions() {
      if (this.currentProduct.isFeatureLab) {
        return 'Only your colleagues may access your reviews on Feature Lab. No users outside of your company have access to your reviews on the platform.';
      }
      else {
        return 'Only your colleagues may access your reviews on Scout. No Scout users outside of your company have access to your reviews on the platform.';
      }
    }
  }
};
</script>
