<template>
  <div class="question-level-selector">
    <label v-if="label">{{ label }}</label>

    <div>
      <button type="button" :class="['select-button selectable bottom-10', { 'selected': isSelected(option.score)}]" v-for="option in options" :key="`${field}-${option.score}`" @click="selectScore(option.score)">{{ option.label }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionLevelSelector',

  props: {
    field: {
      type:    String,
      default: ''
    },

    label: {
      type:    String,
      default: ''
    },

    options: {
      type:    Array,
      default: () => []
    },

    value: {
      default: null
    }
  },

  data() {
    return {
      selectedScore: this.value
    };
  },

  methods: {
    isSelected(score) {
      return this.selectedScore === score;
    },

    selectScore(score) {
      this.selectedScore = (this.selectedScore === score ? null : score);
      this.$emit('change', this.selectedScore);
    }
  }
};
</script>

<style lang="scss" scoped>
  .select-button:not(:last-child) {
    margin-right: 10px;
  }
</style>
