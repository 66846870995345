<template>
  <div class="a-form filter-popover">
    <div class="text-right bottom-15">
      <button type="button" class="pseudo-link-light-gray" @click="clearFilter">
        Clear Filter
      </button>
    </div>

    <div v-if="showSearch" class="keyword-search-container">
      <input
        type="text"
        name="searchTerm"
        id="search-term"
        class="form-control keyword-search-input"
        placeholder="Search..."
        autocomplete="off"
        v-model="searchTerm"
        :disabled="disabled">

      <button v-if="searchTerm" type="button" class="keyword-search-icon clear-icon pseudo-link" @click.stop="clearSearchTerm">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </button>

      <svg-icon v-else name="search" class="base-icon keyword-search-icon"></svg-icon>

      <div v-if="disabled" class="missing-text top-15">
        * Filter not available
      </div>

      <div v-if="noMatches" class="error-text top-15">
        * No matching options
      </div>
    </div>

    <div v-if="showOptionsHeader">
      <div v-if="!isOrLogic" class="vertical-center justify-between semibold-weight tight-lines top-15">
        <div>Filter Criteria:</div>

        <div v-if="!lockContext && !searchTerm.length" class="d-flex col-gap-5">
          Required

          <span v-tooltip.right="tooltipText">
            <svg-icon name="info" class="base-icon smaller"></svg-icon>
          </span>
        </div>
      </div>

      <div v-if="isOrLogic && !searchTerm.length" class="vertical-center justify-between semibold-weight tight-lines top-15">
        <div>
          Required
        </div>

        <div class="d-flex col-gap-5">
          <button type="button" :class="['toggle-button md', { 'toggle-on': isFilterContext }]" @click="switchFilterContext">
            {{ isFilterContext ? 'Yes' : '&nbsp;No' }}
          </button>
        </div>
      </div>
    </div>

    <loading-section name="filterOptions">
      <div v-show="showOptions" :class="['fade-scroll-container', { 'top-15': showOptions, 'scrolled-up': isScrolledUp, 'scrolled-down': isScrolledDown }]">
        <div ref="scrollable" @scroll="checkScroll" class="fade-scrollable">
          <div v-if="searchTerm && searchTerm.length">
            <label
              v-for="(option, index) in filteredOptions"
              :key="option.slug"
              :for="`${field}-filtered-${index}-${uniqueFilterId}`"
              class="check-option vertical medium-large">
              <span class="highlighted" v-html="highlight(option.label)"></span>

              <input
                type="checkbox"
                :name="`filtered${index}`"
                :id="`${field}-filtered-${index}-${uniqueFilterId}`"
                :checked="isTermSelected(option.slug)"
                @change="onChange(option)">

              <span class="check c-box"></span>
            </label>
          </div>

          <div v-else>
            <div v-if="selectedOptions.length" class="check-options">
              <div
                v-for="(option, index) in selectedOptions"
                :key="option.slug"
                class="d-flex justify-between col-gap-10 bottom-10">
                <label
                  class="check-option vertical medium-large no-margin"
                  :for="`${field}-selected-${index}-${uniqueFilterId}`">
                  {{ option.label }}

                  <input
                    type="checkbox"
                    :name="`selected${index}`"
                    :id="`${field}-selected-${index}-${uniqueFilterId}`"
                    :checked="isTermSelected(option.slug)"
                    @change="onChange(option)">
                  <span class="check c-box"></span>
                </label>

                <button
                  type="button"
                  v-if="!isOrLogic && !lockContext"
                  :class="['toggle-button md', { 'toggle-on': termIsFilterContext(option.slug) }]"
                  @click="switchTermContext(option.slug)">
                  {{ termIsFilterContext(option.slug) ? 'Yes' : '&nbsp;No' }}
                </button>
              </div>
            </div>

            <div class="top-15" v-if="selectedOptions.length && availableOptions.length">
              <hr class="no-margin">
            </div>

            <div v-if="availableOptions.length" :class="{ 'top-15': selectedOptions.length }">
              <label
                v-for="(option, index) in availableOptions"
                class="check-option vertical medium-large"
                :for="`${field}-available-${index}-${uniqueFilterId}`"
                :key="option.slug">
                {{ option.label }}

                <input
                  type="checkbox"
                  :name="`available${index}`"
                  :id="`${field}-available-${index}-${uniqueFilterId}`"
                  :checked="isTermSelected(option.slug)"
                  @change="onChange(option)">

                <span class="check c-box"></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!lockContext" class="filter-footer multiline semibold-weight">
        Set the “Required” toggle to “Yes” to ensure all search results meet {{ isOrLogic ? 'one of the' : 'the' }} selected criteria.
      </div>
    </loading-section>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import checkScroll from 'vue-app/shared/mixins/check-scroll.js';
import searchFilter from 'vue-app/shared/mixins/search-filter.js';
import { zip, difference, uniqueId } from 'lodash';

export default {
  name: 'FilterMultiselect',

  components: {
    SvgIcon,
    LoadingSection
  },

  mixins: [
    checkScroll,
    searchFilter
  ],

  props: {
    filterParams: {
      type: Object,
      required: true
    },

    onSearch: {
      type: Function,
      required: true
    },

    options: {
      type: Array,
      default: () => []
    },

    searchService: {
      type: Object,
      required: true
    },

    showSearch: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      disabled: false,
      searchTerm: '',
      tooltipText: 'Set the “Required” toggle to “Yes” to ensure all search results meet the selected criteria. If the “Required” toggle is applied to multiple filter criteria, the search result set will be limited to results that meet all of the selected criteria.'
    };
  },

  computed: {
    availableOptions() {
      return difference(this.options, this.selectedOptions);
    },

    hasPrioritizedOption() {
      return !!this.filterParams.prioritizedOption;
    },

    filteredOptions() {
      return this.prioritizedOptions().filter(option => option.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },

    noMatches() {
      return this.searchTerm && !this.filteredOptions.length;
    },

    selectedOptions() {
      return this.options.filter(option => this.selectedTerms?.includes(option.slug));
    },

    showOptions() {
      return !this.disabled && this.filteredOptions.length;
    },

    showOptionsHeader() {
      return !this.lockContext && !this.noMatches && this.hasSelectedTerms;
    },

    uniqueFilterId() {
      return uniqueId();
    }
  },

  methods: {
    prioritizedOptions() {
      if (this.hasPrioritizedOption) {
        this.options.forEach((option, index) => {
          if (this.filterParams.prioritizedOption.value !== option.label) { return; }
          this.options.splice(index, 1);
          option.label = this.filterParams.prioritizedOption.label;
          this.options.unshift(option);
        });
      }

      return this.options;
    },

    clearFilter() {
      this.clearSearchTerm();
      this.searchService.unsetFilter(this.field);
      this.onSearch();
    },

    clearSearchTerm() {
      this.searchTerm = '';
    },

    highlight(text) {
      const sectionsInbetween = text.split(new RegExp(this.searchTerm, 'i'));
      const highlights        = text.match(new RegExp(this.searchTerm, 'gi')).map(highlight => `<strong>${highlight}</strong>`);
      const zipped            = zip(sectionsInbetween, highlights);

      return zipped.flat().join('');
    },

    onChange(option) {
      const termContext = this.termContext(option.slug);

      if (this.isTermSelected(option.slug)) {
        this.searchService.removeSearchTerm(this.field, option.slug, termContext);
      }
      else {
        this.searchService.addSearchTerms(this.field, option.slug, termContext);
      }

      this.onSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .fade-scroll-container {
    max-height: 170px;

    .fade-scrollable {
      max-height: 170px;
    }
  }

  .highlighted :deep(strong) {
    color: $k-blue;
  }
</style>
