<template>
  <div class="row vertical-center-not-xs">
    <div :class="hideRateOnFile ? 'col-sm-6' : 'col-sm-4'">
      <suggested-lawyer-name-plate :suggested-lawyer="suggestedLawyer"></suggested-lawyer-name-plate>
    </div>

    <div class="col-sm-2 top-10-xs" v-if="!hideRateOnFile">
      <span v-if="lawyerBridge.rate">{{ lawyerBridge.rate }}/hr</span>
      <span v-else>&mdash;</span>
    </div>

    <div class="col-sm-3 top-10-xs">
      <currency-input-vertical
        v-model="proposedHourlyRate"
        :id="`lawyer-rate-${suggestedLawyer.formId}`"
        :name="`lawyerRate${suggestedLawyer.formId}`"
        :rules="rateValidationRules"
        @input="handleInput">
      </currency-input-vertical>
    </div>

    <div class="col-sm-3 text-right-not-xs">
      <button type="button" class="pseudo-link-blue dark-gray-link semibold-weight" @click="removeLawyer"><svg-icon name="minus-circle" class="base-icon right-10"></svg-icon>Remove</button>
    </div>
  </div>
</template>

<script>
import SuggestedLawyerNamePlate from 'vue-app/scout/shared/rfqs/suggested-lawyer-nameplate.vue';
import CurrencyInputVertical from 'vue-app/shared/components/currency-input-vertical.vue';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';

export default {
  name: 'QuoteFormFirmSuggestedLawyer',

  components: {
    SuggestedLawyerNamePlate,
    CurrencyInputVertical
  },

  props: {
    suggestedLawyer: {
      type: Object,
      required: true
    },

    rateIsRequired: {
      type: Boolean,
      default: true
    },

    onChange: {
      type: Function,
      required: true
    },

    onRemove: {
      type: Function,
      required: true
    },

    hideRateOnFile: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      proposedHourlyRate: this.suggestedLawyer.proposedHourlyRate,
      includedInQuote: this.suggestedLawyer.includedInQuote
    };
  },

  computed: {
    lawyerBridge() {
      return new ScoutLawyerBridge(this.suggestedLawyer.scoutLawyerBridge);
    },

    rateValidationRules() {
      return {
        minValue: 0.01,
        required: this.rateIsRequired
      };
    }
  },

  methods: {
    handleInput() {
      this.onChange(this.suggestedLawyer, 'proposedHourlyRate', this.proposedHourlyRate);
    },

    removeLawyer() {
      this.onRemove(this.suggestedLawyer);
    }
  }
};
</script>
