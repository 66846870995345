<template>
  <div>
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-4 size-text-17px bold-weight">
        Note sent to {{ workspace.name }}
      </div>

      <div class="col-sm-8 red-text top-20-xs">
        <div class="vertical-center-not-xs flex-end gap-20">
          <div>
            <svg-icon name="x-circle" class="base-icon"></svg-icon>
            <span class="bold-weight left-5">{{ declinedStatusText }}</span>
          </div>

          <div v-if="!isEditing" class="top-20-xs">
            <button type="button" class="primary-button-compact" @click="editQuote">Edit Quote</button>
          </div>
        </div>
      </div>
    </div>

    <div class="top-10 bottom-30">
      <hr class="no-margin">
    </div>

    <div class="row" v-if="!isEditing">
      <div class="col-sm-8 col-md-7">
        <div class="bold-weight bottom-5">
          Your firm declined this RFP with the following note:
        </div>

        <div>
          <span class="simple-text">{{ quote.reasonForDecline || '—' }}</span>
        </div>
      </div>
    </div>

    <quote-form
      v-else
      class="quote-form"
      :quote="quoteCopy"
      :is-connecting="isConnecting"
      :on-load-firm-lawyers="onLoadFirmLawyers"
      :on-save="onSave"
      :on-update-file-answer="onUpdateFileAnswer"
      :on-submit="onSubmit"
      :is-editing="isEditing"
      :on-cancel="cancel"
      :has-new-question-activities="hasNewQuestionActivities">
    </quote-form>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter';
import QuoteForm from 'vue-app/scout/firm/rfqs/quote-form.vue';
import { compact, cloneDeep } from 'lodash';

export default {
  name: 'QuoteDetailFirmDeclined',

  components: {
    QuoteForm
  },

  props: {
    quote: {
      type: Object,
      required: true
    },

    isConnecting: {
      type: Boolean,
      required: true
    },

    onLoadFirmLawyers: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    onUpdateFileAnswer: {
      type: Function,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    },

    hasNewQuestionActivities: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      isEditing: false,
      quoteCopy: cloneDeep(this.quote)
    };
  },

  computed: {
    dateResponded() {
      return DateFilter.filter(this.quote.dateResponded);
    },

    workspace() {
      return this.quote.workspace;
    },

    declinedStatusText() {
      return compact(['Declined request', this.dateResponded]).join(' on ');
    }
  },

  methods: {
    editQuote() {
      this.isEditing = true;
    },

    cancel() {
      this.isEditing = false;
    }
  }
};
</script>
