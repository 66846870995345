<template>
  <div class="d-flex wrap row-gap-8 col-gap-8">
    <editable-scout-tag
      v-for="scoutTag in orderedTags"
      :scout-tag="scoutTag"
      :key="scoutTag.id"
      :on-remove-tag="onRemoveTag">
    </editable-scout-tag>
  </div>
</template>

<script>
import EditableScoutTag from './editable-scout-tag.vue';
import { orderBy } from 'lodash';

export default {
  name: 'ScoutTagsList',

  components: {
    EditableScoutTag
  },

  props: {
    scoutTags: {
      type: Array,
      required: true
    },

    onRemoveTag: {
      type: Function,
      required: true
    }
  },

  computed: {
    orderedTags() {
      return orderBy(this.scoutTags, 'position');
    }
  }
};
</script>
