import BaseResource from 'resources/base-resource';
import axios from 'axios';

class RFQ extends BaseResource {
  static baseUrl = '/scout/rfqs/:id.json';

  static submit(params) {
    return axios.post('/scout/rfqs/submit.json', params);
  }

  static updateProjectDetails(params) {
    return axios.putForm(`/scout/rfqs/${params.id}/update_project_details`, params);
  }

  static updateRecipients(id, params) {
    return axios.put(`/scout/rfqs/${id}/update_recipients.json`, params);
  }

  static updateQuestions(id, params) {
    return axios.put(`/scout/rfqs/${id}/update_questions.json`, params);
  }

  static sendToMarketplace(id, params) {
    return axios.put(`/scout/rfqs/${id}/send_to_marketplace.json`, params);
  }

  static recentlySent(params) {
    return axios.get('/scout/rfqs/recently_sent.json', params);
  }

  hasComparableQuotes() {
    return this.lawFirmQuotes.some(q => (q.status === 'selected_by_client' || q.status === 'quote_submitted') && !q.fromMarketplace);
  }
}

export default RFQ;
