<template>
  <div class="quote-section">
    <div class="row tight-columns bottom-40">
      <div class="col-sm-8">
        <div v-if="selectedQuote.fromMarketplace" class="size-text-12px bold-weight purple-text">
          Priori&rsquo;s Marketplace Network
        </div>

        <div v-else class="size-text-12px bold-weight night-blue-text">
          Your Firm
        </div>

        <div v-if="selectedQuote.fromMarketplace">
          <div class="tight-lines">
            <rfq-marketplace-response-modal
              class="inline-block right-10"
              :possible-lawyer="selectedQuote.possibleLawyer"
              :name="marketplaceProviderName"
              :added-as-firm="marketplaceAddedAsFirm">
            </rfq-marketplace-response-modal>

            <a href="mailto:admin@priorilegal.com"><svg-icon name="envelope" class="bottom-5 base-icon mail-icon lighter-gray"></svg-icon></a>
          </div>

          <div v-if="shouldShowTitle">
            {{ selectedQuote.marketplaceLawyer.firmTitle }}
            <span v-if="shouldShowFirmName">at {{ selectedQuote.lawFirm.name }}</span>
          </div>
        </div>

        <law-firm-name-plate
          v-else
          :law-firm-bridge="selectedQuote.lawFirmBridge"
          :emails-to-receive-updates="selectedQuote.emailsToReceiveUpdates">
        </law-firm-name-plate>
      </div>

      <div v-if="!selectedQuote.fromMarketplace" class="col-sm-4 messaging-button-container top-10-xs">
        <messaging-button
          :rfq="rfq"
          :quote="selectedQuote">
        </messaging-button>
      </div>
    </div>

    <div v-if="quoteReceived" class="row tight-columns">
      <div class="col-sm-6 tight-lines">
        <div v-if="selectedQuote.lastUpdatedAt" class="vertical-center bold-weight bottom-5">
          {{ selectedQuoteStatusDisplay }} on {{ formatDate(selectedQuote.lastUpdatedAt) }}<div class="dot"></div>
        </div>

        <div v-else class="bold-weight bottom-5">
          {{ selectedQuoteStatusDisplay }} on {{ formatDate(selectedQuote.dateResponded) }}
        </div>

        <button type="button" @click="downloadQuote" class="pseudo-link-blue bold-weight">
          Download Quote
        </button>
      </div>

      <div class="col-sm-6 top-20-xs">
        <div class="quote-actions">
          <interview-button
            v-if="canEditRfq && selectedQuote.fromMarketplace"
            :update-quote="updateQuoteWithEvent"
            :interview-requested="interviewRequested"
            :selected-quote="selectedQuote"
            :rfq="rfq">
          </interview-button>

          <select-firm-button
            v-if="canEditRfq && !selectedQuote.fromMarketplace"
            :update-quote="updateQuoteWithEvent"
            :is-selected="isSelected"
            :selected-quote="selectedQuote"
            :rfq="rfq">
          </select-firm-button>

          <decline-firm-button
            v-if="canEditRfq"
            :update-quote="updateQuoteWithEvent"
            :is-declined="!interviewRequested && isDeclined"
            :selected-quote="selectedQuote"
            :rfq="rfq"
            :is-marketplace="selectedQuote.fromMarketplace">
          </decline-firm-button>

          <edit-note-modal
            v-if="canEditRfq"
            :quote="selectedQuote"
            :rfq="rfq">
          </edit-note-modal>
        </div>
      </div>
    </div>

    <div v-if="quotePending">
      <div class="tight-lines bottom-40">
        <div class="size-text-17px bold-weight bottom-5">
          {{ selectedQuoteStatusDisplay }}
        </div>

        <div>
          RFP submitted on {{ formatDate(selectedQuote.dateReceived) }}
        </div>
      </div>

      <priori-modal ref="previewRfq" modal-id="preview-rfq" modal-size="lg" :title="previewModalTitle">
        <template #modal-trigger="{ openModal }">
          <a href="" @click.prevent="openModal" class="bold-weight">Preview RFP</a>
        </template>

        <rfq-preview :rfq="rfq" :law-firm-quote="selectedQuote"></rfq-preview>
      </priori-modal>
    </div>

    <div v-if="quoteDeclined">
      <div class="tight-lines bottom-40">
        <div class="size-text-17px bold-weight bottom-5">
          {{ selectedQuoteStatusDisplay }}
        </div>

        <div>
          Declined on {{ formatDate(selectedQuote.dateResponded) }}
        </div>
      </div>

      <div class="size-text-15px bold-weight heading-underline bottom-15">
        Law Firm Response
      </div>

      <div>
        <span class="simple-text">{{ selectedQuote.reasonForDecline }}</span>
      </div>
    </div>

    <quote-detail
      v-if="quoteReceived"
      class="top-40"
      :rfq="rfq"
      :quote="selectedQuote"
      :suggested-lawyers="selectedQuote.suggestedLawyers"
      :viewing-as-client="true">
    </quote-detail>
  </div>
</template>

<script>
import EditNoteModal from 'vue-app/scout/client/rfqs/edit-note-modal.vue';
import LawFirmNamePlate from 'vue-app/scout/client/rfqs/law-firm-name-plate.vue';
import RfqPreview from 'vue-app/scout/client/rfqs/preview.vue';
import QuoteDetail from 'vue-app/scout/shared/rfqs/quote-detail.vue';
import SelectFirmButton from 'vue-app/scout/client/rfqs/review-responses/select-firm-button.vue';
import DeclineFirmButton from 'vue-app/scout/client/rfqs/review-responses/decline-firm-button.vue';
import InterviewButton from 'vue-app/scout/client/rfqs/review-responses/interview-button.vue';
import RfqMarketplaceResponseModal from 'vue-app/scout/client/rfqs/marketplace-response-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import MessagingButton from 'vue-app/mailboxer/scout/messaging-button.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import NotificationService from 'vue-app/shared/services/notification-service';
import RfqQuoteToDocx from 'src/services/scout/client/rfq-quote-to-docx';

export default {
  name: 'SelectedResponse',

  components: {
    EditNoteModal,
    LawFirmNamePlate,
    RfqPreview,
    QuoteDetail,
    SelectFirmButton,
    DeclineFirmButton,
    InterviewButton,
    RfqMarketplaceResponseModal,
    SvgIcon,
    MessagingButton
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    canEditRfq: {
      type: Boolean,
      required: true
    },

    selectedQuote: {
      type: Object
    },

    updateQuote: {
      type: Function,
      required: true
    },

    updateSelectedQuote: {
      type: Function,
      required: true
    }
  },

  computed: {
    quoteReceived() {
      if (!this.selectedQuote) { return false; }
      return ['quote_submitted', 'selected_by_client', 'declined_by_client'].includes(this.selectedQuote.status) || this.selectedQuote.interviewRequested;
    },

    quotePending() {
      if (!this.selectedQuote) { return false; }
      return ['new_rfq', 'draft'].includes(this.selectedQuote.status);
    },

    quoteDeclined() {
      if (!this.selectedQuote) { return false; }
      return this.selectedQuote.status === 'declined_by_firm';
    },

    selectedQuoteStatusDisplay() {
      let displayText = '';

      if (this.quoteReceived) {
        displayText = this.selectedQuote.lastUpdatedAt ? 'Quote Updated' : 'Quote Received';
      }
      else if (this.quotePending) {
        displayText = 'Pending Response from Firm';
      }
      else {
        displayText = 'Quote Declined';
      }
      return displayText;
    },

    previewModalTitle() {
      return `Preview RFP for ${this.selectedQuote.lawFirmBridge.name}`;
    },

    interviewRequested() {
      return this.selectedQuote?.interviewRequested;
    },

    isSelected() {
      return this.selectedQuote?.status === 'selected_by_client';
    },

    isDeclined() {
      return this.selectedQuote?.status === 'declined_by_client';
    },

    declineNotificationSuccessMsg() {
      return this.selectedQuote.fromMarketplace ? 'You have successfully declined this provider.' : 'The firm was successfully declined and notified.';
    },

    marketplaceAddedAsFirm() {
      return this.selectedQuote.marketplaceLawyer.addedAsFirm;
    },

    marketplaceProviderName() {
      return this.marketplaceAddedAsFirm ? this.selectedQuote.lawFirm.name : this.selectedQuote.possibleLawyer.lawyer.fullName;
    },

    shouldShowTitle() {
      return !this.marketplaceAddedAsFirm && !!this.selectedQuote.marketplaceLawyer.firmTitle;
    },

    shouldShowFirmName() {
      return this.selectedQuote.lawFirm.name !== this.selectedQuote.possibleLawyer.lawyer.fullName;
    }
  },

  methods: {
    formatDate(date) {
      return DateFilter.filter(date);
    },

    updateQuoteWithEvent(rfq, selectedQuote, event) {
      return this.updateQuote(rfq, selectedQuote, event)
        .then((response) => {
          this.updateSelectedQuote(response.lawFirmQuotes.find(quote => quote.id === this.selectedQuote.id));

          if (event === 'select_firm') {
            this.selectedQuote.status = 'selected_by_client';
            NotificationService.success('The firm was successfully selected and notified.');
          }
          else if (event === 'decline_firm') {
            this.selectedQuote.status = 'declined_by_client';
            NotificationService.success(this.declineNotificationSuccessMsg);
          }
          else if (event === 'requested_interview') {
            this.selectedQuote.status = 'requested_interview';
            NotificationService.success('You have successfully requested an interview.');
          }
        });
    },

    downloadQuote() {
      const docx = new RfqQuoteToDocx(this.rfq, [this.selectedQuote]);
      docx.generateDocx();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .quote-section {
    @media (min-width: $screen-sm-min) {
      padding-left: 20px;
    }

    @media (min-width: $screen-md-min) {
      padding-left: 30px;
    }

    @media (min-width: $screen-lg-min) {
      padding-left: 40px;
    }
  }

  .dot {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    background-color: $p-yellow;
    border-radius: 50%;
    margin-left: 5px;
  }

  .messaging-button-container {
    height: 60px;
  }

  .quote-actions {
    :deep( > :not(:last-child)) {
      margin-bottom: 10px;
    }
  }
</style>
