import BaseResource from '../base-resource';
import axios from 'axios';

class ScoutFirm extends BaseResource {
  static baseUrl = '/scout/law_firms/:id.json';

  static requestDataRemoval(params) {
    return axios.post('/scout/law_firms/request_data_removal', params);
  }

  static rfqAvailableFirms() {
    return axios.get('/scout/law_firms/rfq_available_firms.json');
  }
}

export default ScoutFirm;
