<template>
  <div class="clear-panel compact-panel">
    <div class="row tight-columns notification-row vertical-center-not-xs">
      <div class="icon-column">
        <div class="notification-icon">
          <svg-icon name="notification" class="base-icon"></svg-icon>
        </div>
      </div>

      <div :class="['text-column top-20-xs', { 'full-width': isConnecting }]">
        <div class="bold-weight bottom-2">
          {{ workspaceName }}
        </div>

        <div class="pill-gray-text tight-lines">
          Action Required: {{ countText }}
        </div>
      </div>

      <div v-if="!isConnecting" class="link-column top-20-xs">
        <a :href="`/scout-firm/clients/${workspaceId}/rfps`" class="bold-weight">View Client RFPs</a>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { compact } from 'lodash';

export default {
  name: 'RfqNotification',

  components: {
    SvgIcon
  },

  props: {
    notification: {
      type: Object,
      required: true
    },

    isConnecting: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    workspaceName() {
      return this.notification.workspace.name;
    },

    workspaceId() {
      return this.notification.workspace.id;
    },

    newRfqText() {
      if (this.notification.newRfqCount === 0) { return; }

      // Our pluralize library converts RFP to RFPS with a capital 'S' and I
      // couldn't figure out how to add inflection rules to fix it
      return this.$pluralize('New RFP', this.notification.newRfqCount, true).replace('RFPS', 'RFPs');
    },

    draftRfqText() {
      if (this.notification.draftRfqCount === 0) { return; }
      return this.$pluralize('Draft', this.notification.draftRfqCount, true);
    },

    updatedRfqText() {
      if (this.notification.updatedRfqIds.length === 0) { return; }
      return this.notification.updatedRfqIds.length === 1 ? '1 RFP has been updated' : `${this.notification.updatedRfqIds.length} RFPs have been updated`;
    },

    countText() {
      return compact([this.newRfqText, this.draftRfqText, this.updatedRfqText]).join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .notification-row {
    .notification-icon {
      position: relative;
      width: 22px;
      height: 22px;

      .base-icon {
        width: 22px;
        height: 22px;
        fill: $k-dark-gray;
      }

      &::after {
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 1px;
        right: 1px;
        background-color: $scout-red;
        border-radius: 50%;
      }
    }

    .icon-column, .text-column, .link-column {
      width: 100%;
      padding-right: 5px;
      padding-left: 5px;
    }

    @media (min-width: 768px) {
      // col-sm-2 shifting 15px into the text column
      .icon-column {
        width: calc((100% * (2 / 12)) - 15px);
      }

      // col-sm-6 plus 15px
      .text-column {
        width: calc((100% * (6 / 12)) + 15px);

        &.full-width {
          width: calc((100% * (10 / 12)) + 15px);
        }
      }

      // col-sm-4
      .link-column {
        width: calc(100% * (4 / 12));
        text-align: right;
      }
    }
  }
</style>
