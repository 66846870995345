<template>
  <div class="row tight-columns">
    <div class="col-sm-4 clickable" @click="viewLawyer">
      <lawyer-name-plate :lawyer="lawyer" :hide-firm="true"></lawyer-name-plate>
    </div>

    <div class="col-sm-7">
      <div class="row match-row tight-columns vertical-center-not-xs">
        <div class="col-sm-3 text-center-not-xs">
          <match-tag :score="lawyer.matchScore" v-if="shouldShowMatchScore"></match-tag>
          <span v-else>&mdash;</span>
        </div>

        <div class="col-sm-3 text-center-not-xs">
          <span v-if="lawyer.rate">{{ lawyer.rate }}/hr</span>
          <span v-else>&mdash;</span>
        </div>

        <div class="col-sm-3 text-center-not-xs">
          <div v-if="recommendationScore > 0">
            <div class="semibold-weight">
              {{ recommendationScore }} / 10
            </div>

            <div class="size-text-12px">
              ({{ reviewCount }})
            </div>
          </div>

          <div v-else>
            &mdash;
          </div>
        </div>

        <div class="col-sm-3 text-center-not-xs">
          {{ lawyer.matterCount || 0 }}
        </div>
      </div>
    </div>

    <div class="col-sm-1 text-right-not-xs">
      <dropdown-menu :compact="true" class="top-5">
        <li class="menu-item" role="menuitem">
          <a :href="`/scout-company/lawyers/${lawyer.id}`" target="_blank">View Profile</a>
        </li>

        <li class="menu-item" role="menuitem" v-if="lawyer.hasVisibleProfile">
          <a href="" @click.prevent="toggleFavorite">{{ lawyer.isFavorite ? 'Unfavorite' : 'Favorite' }}</a>
        </li>

        <li class="menu-item" role="menuitem" v-if="lawyer.hasVisibleProfile">
          <modal-add-to-list
            :resource-lists="resourceLists"
            :resource="lawyer"
            :on-create-list="onCreateList"
            :on-add-to-list="onAddToList">
          </modal-add-to-list>
        </li>
      </dropdown-menu>
    </div>
  </div>
</template>

<script>
import LawyerNamePlate from 'src/vue-app/scout/shared/lawyer-name-plate.vue';
import DropdownMenu from 'src/vue-app/shared/components/dropdown-menu.vue';
import MatchTag from 'src/vue-app/scout/shared/match-tag.vue';
import ModalAddToList from 'src/vue-app/scout/shared/searches/modal-add-to-list.vue';
import { isNumber } from 'lodash';

export default {
  name: 'FirmLawyer',

  components: {
    LawyerNamePlate,
    DropdownMenu,
    MatchTag,
    ModalAddToList
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    filterApplied: {
      type: Boolean,
      default: false
    },

    onToggleFavorite: {
      type: Function,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    onCreateList: {
      type: Function,
      required: true
    },

    onAddToList: {
      type: Function,
      required: true
    },

    onView: {
      type: Function,
      required: true
    }
  },

  computed: {
    shouldShowMatchScore() {
      return this.filterApplied && isNumber(this.lawyer.matchScore);
    },

    recommendationScore() {
      return Number(this.lawyer.reviews.score).toFixed(1);
    },

    reviewCount() {
      return this.$pluralize('review', this.lawyer.reviews.count, true);
    }
  },

  methods: {
    viewLawyer() {
      this.onView(this.lawyer);
    },

    toggleFavorite() {
      if (!this.lawyer.hasVisibleProfile) { return; }

      this.onToggleFavorite(this.lawyer);
    }
  }
};
</script>
