<template>
  <div class="resource-list">
    <div class="list-header">
      <div class="row tight-columns">
        <div class="col-sm-2">
          File #
        </div>

        <div class="col-sm-2">
          Date
        </div>

        <div class="col-sm-2">
          Time
        </div>

        <div class="col-sm-2">
          Status
        </div>

        <div class="col-sm-2">
          # Errors
        </div>
      </div>
    </div>

    <div>
      <div class="list-item" v-for="upload in uploads" :key="upload.id">
        <upload-list-item :upload="upload" :url-constructor="urlConstructor"></upload-list-item>
      </div>

      <div class="list-item empty gray-text size-text-16px" v-if="!uploads.length">
        No uploads
      </div>
    </div>
  </div>
</template>

<script>
import UploadListItem from './upload-list-item.vue';

export default {
  name: 'UploadList',

  components: { UploadListItem },

  props: {
    uploads: {
      type:     Array,
      required: true
    },

    urlConstructor: {
      type: Function,
      required: true
    }
  }
};
</script>
