import ScoutMatter from './scout-matter.js';
import BaseResource from '../base-resource';
import axios from 'axios';

class ScoutFirmBridge extends BaseResource {
  static baseUrl = '/scout/law_firm_bridges/:id.json';

  static scoutMatters(id, params) {
    return axios.get(`/scout/law_firm_bridges/${id}/scout_matters.json`, { params: params })
      .then((data) => {
        return data.data.map(matter => new ScoutMatter(matter));
      });
  }

  static profileView(id, params) {
    return axios.get(`/scout/law_firm_bridges/${id}/profile_view.json`, { params: params });
  }

  isBillingFirm() {
    return this.accessLevel === 'billing_data';
  }

  isConnectedFirm() {
    return this.accessLevel === 'connected_data';
  }

  isProspectiveFirm() {
    return this.accessLevel === 'prospective_data';
  }
}

export default ScoutFirmBridge;
