<template>
  <edit-modal title="Suggest a New Client" :input="message" :on-save="submit" save-button-text="Submit">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="secondary-btn-blue text-ellipsis" @click="openModal">Suggest A New Client</button>
    </template>

    <template #default="{ inputCopy: messageCopy }">
      <div class="bottom-30">
        Recommend a client you would like to have available on Scout:
      </div>

      <div class="bottom-30">
        <validation-provider rules="required" v-slot="{ errors }">
          <label for="name">Client Name <span class="inline-help required">*</span></label>
          <input type="text" v-model="messageCopy.name" name="name" id="name" class="form-control">

          <error-message v-if="errors.length" class="error-text" :errors="errors"></error-message>
        </validation-provider>
      </div>

      <div class="bottom-30">
        <label for="note">Add a note (optional)</label>
        <textarea class="form-control" id="note" v-model="messageCopy.note" placeholder="Enter message..." rows="6"></textarea>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import InternalMessage from 'vue-app/shared/resources/internal-message';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'SuggestAClient',

  components: {
    EditModal,
    ValidationProvider
  },

  data() {
    return {
      message: {}
    };
  },

  methods: {
    submit(messageCopy) {
      return new InternalMessage({
        domain: 'scout_client_suggestion',
        parts: [
          { title: 'Client Name', body: messageCopy.name },
          { title: 'Note', body: messageCopy.note }
        ]
      }).save();
    }
  }
};
</script>
