<template>
  <div :class="['shadowed-box', 'notification-box', variant]">
    <span v-if="icon" class="icon-wrapper right-10">
      <svg-icon :name="icon" class="base-icon icon"></svg-icon>
    </span>

    <div class="inline-block">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'NotificationBanner',

  components: {
    SvgIcon
  },

  props: {
    icon: {
      type:    String,
      required: false
    },

    variant: {
      type:     String,
      default:  'info'
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .shadowed-box {
    min-height: unset;
  }

  .icon-wrapper {
    position: relative;
    width: 22px;

    .base-icon {
      width: 20px;
      fill: $k-dark-gray;
    }

    &::after {
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      top: 1px;
      right: -3px;
      background-color: $red-4;
      border-radius: 50%;
    }
  }

  .notification-box {
    padding: 10px 20px 10px 13px;

    &.info {
      border-left: 7px solid $k-blue;
    }
  }
</style>
