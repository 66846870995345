<template>
  <div>
    <label :for="`question-${question.id}-min`" :class="{ 'disabled-text': !question.isIncluded }">
      {{ question.questionText }} <span class="inline-help required" v-if="question.isRequired">*</span>
      <scout-tag v-if="showQuestionUpdateTag" :scout-tag="tagData"></scout-tag>
    </label>

    <div class="row tight-columns">
      <div class="col-sm-3">
        <currency-input-vertical
          v-model="answerMin"
          :id="`question-${question.id}-min`"
          :name="`question${question.id}Min`"
          :placeholder="answerIsRange ? 'Bottom of range' : ''"
          :rules="validationRulesMin"
          @input="handleInput"
          :disabled="!question.isIncluded">
        </currency-input-vertical>
      </div>

      <div class="col-sm-1 text-center top-10 hidden-xs" v-if="answerIsRange">
        &mdash;
      </div>

      <div class="col-sm-3 top-10-xs" v-if="answerIsRange">
        <currency-input-vertical
          v-model="answerMax"
          :id="`question-${question.id}-max`"
          :name="`question${question.id}Max`"
          placeholder="Top of range"
          :rules="validationRulesMax"
          :custom-error-messages="{ minValue: `* Can't be less than bottom of range` }"
          @input="handleInput"
          :disabled="!question.isIncluded">
        </currency-input-vertical>
      </div>
    </div>

    <div class="size-text-12px top-2">
      <button v-if="question.isIncluded" type="button" class="pseudo-link-blue bold-weight" @click="toggleRange">{{ buttonText }}</button>
    </div>
  </div>
</template>

<script>
import CurrencyInputVertical from 'vue-app/shared/components/currency-input-vertical.vue';
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';

import { compact, startCase } from 'lodash';

export default {
  name: 'QuoteFormQuestionRange',

  components: {
    CurrencyInputVertical,
    ScoutTag
  },

  props: {
    minValue: {
      type: [String, Number]
    },

    maxValue: {
      type: [String, Number]
    },

    isRange: {
      type: Boolean,
      default: false
    },

    question: {
      type: Object,
      required: true
    },

    onChange: {
      type: Function,
      required: true
    },

    showQuestionUpdateTag: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      answerMin: this.minValue,
      answerMax: this.maxValue,
      answerIsRange: this.isRange
    };
  },

  computed: {
    buttonText() {
      if (this.answerIsRange) {
        return 'Express estimate as a single dollar amount';
      }
      else {
        return 'Express estimate as a range';
      }
    },

    validationRulesMin() {
      return {
        minValue: 0.01,
        required: this.question.isRequired && this.question.isIncluded
      };
    },

    validationRulesMax() {
      return {
        minValue: `@question${this.question.id}Min`,
        required: this.question.isRequired && this.question.isIncluded
      };
    },

    tagData() {
      return {
        color: 'yellow',
        name: this.activityText
      };
    },

    activityText() {
      return startCase(this.question.latestActivity?.activityType);
    }
  },

  methods: {
    toggleRange() {
      this.answerMin = null;
      this.answerMax = null;
      this.answerIsRange = !this.answerIsRange;

      this.handleInput();
    },

    handleInput() {
      this.onChange(compact([this.answerMin, this.answerMax]));
    }
  }
};
</script>
