<template>
  <validation-provider :rules="validationRules" v-slot="{ errors, failed }" :mode="passiveAggressive">
    <div>
      <div :class="['bottom-5', { 'disabled-text': !question.isIncluded }, isAdminForm ? 'semibold-weight' : 'bold-weight']">
        {{ question.questionText }} <span class="inline-help required" v-if="question.isRequired">*</span>
        <scout-tag v-if="showQuestionUpdateTag" :scout-tag="tagData"></scout-tag>
      </div>

      <div :class="['list-spacing-8', { 'has-error': failed, 'disabled-text': !question.isIncluded }]" v-for="option in question.questionOptions">
        <label class="check-option vertical medium-large">
          <span class="normal-weight">{{ startCase(option) }}</span>
          <input
            type="radio"
            :id="`question-${question.id}-${option}`"
            :name="`question${question.id}`"
            :value="option"
            v-model="answer"
            :disabled="!question.isIncluded"
            @change="handleChange">
          <span class="check c-radio"></span>
        </label>
      </div>
    </div>

    <div class="error-text top-5" v-if="errors.length">
      {{ errors[0] }}
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import ScoutTag from 'vue-app/scout/shared/scout-tags/scout-tag.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import { startCase } from 'lodash';

export default {
  name: 'QuoteFormQuestionOption',

  components: {
    ValidationProvider,
    ScoutTag
  },

  mixins: [
    interactionModes
  ],

  props: {
    value: {
      type: String
    },

    question: {
      type: Object,
      required: true
    },

    onChange: {
      type: Function,
      required: true
    },

    showQuestionUpdateTag: {
      type: Boolean,
      default: false
    },

    isAdminForm: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      answer: this.value
    };
  },

  computed: {
    validationRules() {
      return this.question.isRequired && this.question.isIncluded ? 'required' : '';
    },

    tagData() {
      return {
        color: 'yellow',
        name: this.activityText
      };
    },

    activityText() {
      return startCase(this.question.latestActivity?.activityType);
    }
  },

  methods: {
    startCase(value) {
      return startCase(value);
    },

    handleChange() {
      this.onChange(this.answer);
    }
  }
};
</script>
