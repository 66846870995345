<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-6 text-uppercase">
          Matters by Practice Area
        </div>

        <div class="col-sm-6 size-text-12px normal-weight text-right-not-xs">
          <a href="" :class="['text-link right-20', { 'bold-weight': isActiveGraphic('distribution') }]" @click.prevent="setGraphic('distribution')">Total Matters</a>

          <a href="" :class="['text-link', { 'bold-weight': isActiveGraphic('dollarsSpent') }]" @click.prevent="setGraphic('dollarsSpent')">Dollars Spent</a>
        </div>
      </div>
    </div>

    <div class="box-content">
      <matter-distribution v-if="isActiveGraphic('distribution')" :datapoints="formattedPracticeAreas"></matter-distribution>

      <matter-dollars-spent v-if="isActiveGraphic('dollarsSpent')" :datapoints="formattedPracticeAreas"></matter-dollars-spent>
    </div>
  </div>
</template>

<script>
import MatterDistribution from './matter-distribution.vue';
import MatterDollarsSpent from './matter-dollars-spent.vue';

export default {
  name: 'MatterGraphics',

  components: {
    MatterDistribution,
    MatterDollarsSpent
  },

  props: {
    formattedPracticeAreas: {
      type:     Array,
      required: true
    }
  },

  data: function () {
    return { activeGraphic: 'distribution' };
  },

  methods: {
    setGraphic: function (name) {
      this.activeGraphic = name;
    },

    isActiveGraphic: function (name) {
      return this.activeGraphic === name;
    }
  }
};
</script>
