<template>
  <priori-modal ref="contactFirmModal" modal-id="contact-firm-modal" modal-size="md" title="Contact Law Firm">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openModal"><svg-icon name="envelope" class="base-icon mail-icon lighter-gray"></svg-icon></a>
    </template>

    <div class="bottom-40">
      <div class="row bottom-10">
        <div class="col-sm-8 size-text-15px semibold-weight">
          RFP Managers for {{ firmName }}:
        </div>

        <div v-if="hasEmails" class="col-sm-4 semibold-weight blue-text text-right-not-xs">
          <copy-to-clipboard :text-to-copy="emails.join(',')" tooltip-text="Addresses copied to clipboard">
            <svg-icon name="copy" class="base-icon right-3 bottom-5"></svg-icon>Copy All Addresses
          </copy-to-clipboard>
        </div>
      </div>

      <div v-if="hasEmails">
        <div v-for="email in emails" class="list-spacing-5">
          {{ email }}
        </div>
      </div>

      <div v-else class="dark-gray-text size-text-14px">
        This firm has no assigned RFP managers.
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <button type="button" class="nv-button-white" @click="closeModal">Close</button>
      </div>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';
import CopyToClipboard from 'vue-app/shared/components/copy-to-clipboard.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RfqContactFirmModal',

  components: {
    PrioriModal,
    CopyToClipboard,
    SvgIcon
  },

  mixins: [
    modalToggle
  ],

  props: {
    firmName: {
      type: String,
      required: true
    },

    emails: {
      type: Array,
      required: true
    }
  },

  computed: {
    hasEmails() {
      return this.emails.length > 0;
    }
  },

  methods: {
    closeModal() {
      this.$refs.contactFirmModal.closeModal();
    }
  }
};
</script>
