<template>
  <span :class="['scout-tag', scoutTag.color]">{{ scoutTag.name }}</span>
</template>

<script>
export default {
  name: 'ScoutTag',

  props: {
    scoutTag: {
      type: Object,
      required: true
    }
  }
};
</script>
