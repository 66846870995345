<template>
  <edit-modal title="Send us Feedback" :input="message" :on-save="submit" save-button-text="Submit">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="secondary-btn-blue text-ellipsis" @click="openModal">Send Feedback</button>
    </template>

    <template #default="{ inputCopy: messageCopy }">
      <div class="bottom-30">
        Tell us about your Scout experience:
      </div>

      <div class="bottom-30">
        <validation-provider rules="required" v-slot="{ errors }">
          <label for="feedback">Feedback <span class="inline-help required">*</span></label>
          <textarea class="form-control" id="feedback" v-model="messageCopy.feedback" placeholder="Enter message..." rows="6"></textarea>

          <error-message v-if="errors.length" class="error-text" :errors="errors"></error-message>
        </validation-provider>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import InternalMessage from 'vue-app/shared/resources/internal-message';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'ScoutFeedback',

  components: {
    EditModal,
    ValidationProvider
  },

  data() {
    return {
      message: {}
    };
  },

  methods: {
    submit(messageCopy) {
      return new InternalMessage({
        domain: 'scout_feedback',
        parts: [
          { title: 'Feedback', body: messageCopy.feedback }
        ]
      }).save();
    }
  }
};
</script>
